import { transformItineraryToValue, TransformedItinerary } from '@aclass/admin/components/package.itinerary';
import { OrmManager } from '@aclass/admin/managers';
import { OpportunityViewStateResolver } from '@aclass/admin/resolvers';
import { viewDocumentRs, viewSendEmailData } from '@aclass/admin/rest/responses';
import {
    CustomerStory,
    DashboardStory,
    OpportunityStory,
    OrmStory,
    PipelineStory,
    QuoteStory,
    RootStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import {
    Customer,
    EmailTemplate,
    ICombinationRevisionData,
    IConsentData,
    IConsentOpportunityMappingData,
    ICountryData,
    ICustomerEmailMessageLogData,
    ICustomerLogData,
    IDeliveryData,
    IDestinationRevisionData,
    IDestinationToTourRevisionMappingData,
    IDocumentCustomerMappingData,
    IDocumentData,
    IDocumentEmailTemplateMappingData,
    IDocumentOfflineBookingMapping,
    IDocumentOpportunityMappingData,
    IEmailTemplateData,
    IItineraryData,
    IItineraryPriceData,
    IOfflineBookingPassengerData,
    IOfflineBookingPayment,
    IOpportunityEmailMessageLogData,
    IOpportunityLogData,
    IPCCombinationData,
    IPCTourData,
    IPCTourPackageData,
    ITourRevisionData, IVamoosData,
    Opportunity, PipelineLevel
} from '@aclass/admin/storage/models';
import { orm } from '@aclass/admin/storage/orm';
import { IAdminState } from '@aclass/admin/storage/states';
import { OPPORTUNITY_EDIT_SEGMENTS } from '@aclass/admin/utils';
import { readToBase64, tryToSaveFile, IFileData } from '@aclass/core/base/file.reader';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { findById } from '@aclass/core/helpers/orm';
import { Order } from '@aclass/core/rest/order';
import { Pagination } from '@aclass/core/rest/pagination';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response, Transformed } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IOfflineBookingData } from '@aclass/core/storage/models/offline.booking';
import { IOpportunityData } from '@aclass/core/storage/models/opportunity';
import { IPipelineLevelData } from '@aclass/core/storage/models/pipeline.level';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { IUserData } from '@aclass/core/storage/models/user';
import { PIPELINE_LEVEL_PRESETS_LEVELS } from '@aclass/core/utils/opportunities';
import { HttpResponse } from '@angular/common/http';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of, zip } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

type transformed = Transformed<IOpportunityData, {

    pipeline?: Transformed<IPipelineLevelData>;

    assigned?: Transformed<IUserData>;

    package?: Transformed<IPCTourPackageData>;

    tourRevision?: Transformed<ITourRevisionData, {

        pcTour?: Transformed<IPCTourData>;

        destinationRevisions?: Array<Transformed<IDestinationRevisionData>>;

        destinationToTourRevisionMappings?: Array<Transformed<IDestinationToTourRevisionMappingData>>;
    }>;

    combinationRevision?: Transformed<ICombinationRevisionData, {
        pcCombination?: Transformed<IPCCombinationData>;
    }>;

    emails?: Array<Transformed<IOpportunityEmailMessageLogData>>;

    parkedEmails?: Transformed<IOpportunityEmailMessageLogData>;

    logs?: Array<Transformed<IOpportunityLogData>>;

    documents?: Array<Transformed<IDocumentData>>;

    travelWiseDocuments?: Array<Transformed<IDocumentData>>;

    documentMappings?: Array<Transformed<IDocumentOpportunityMappingData>>;

    consents?: Array<Transformed<IConsentData>>;

    consentsMappings?: Array<Transformed<IConsentOpportunityMappingData>>;

    documentTravelWiseMappings?: Array<Transformed<IDocumentOpportunityMappingData>>;

    tourItinerary?: Transformed<IItineraryData, {
        prices: Array<Transformed<IItineraryPriceData>>;
    }>;

    combinationItinerary?: Transformed<IItineraryData, {
        prices: Array<Transformed<IItineraryPriceData>>;
    }>;

    customer?: Transformed<ICustomerData, {

        country?: Transformed<ICountryData>;

        logs?: Array<Transformed<ICustomerLogData>>;

        emails?: Array<Transformed<ICustomerEmailMessageLogData>>;

        documents?: Array<Transformed<Transformed<IDocumentData>>>;

        documentMappings?: Array<Transformed<IDocumentCustomerMappingData>>;
    }>;

    offlineBooking?: Transformed<IOfflineBookingData, {

        documents?: Array<Transformed<IDocumentData>>;

        documentMappings?: Array<Transformed<IDocumentOfflineBookingMapping>>;
    }>;

    offlineBookingPassengers?: Array<Transformed<IOfflineBookingPassengerData>>;

    offlineBookingPayments?: Array<Transformed<IOfflineBookingPayment>>;

    vamoos?: Transformed<IVamoosData>;

    delivery?: Transformed<IDeliveryData, {

        tourItinerary?: TransformedItinerary,

        combinationItinerary?: TransformedItinerary
    }>;

    offers?: Array<Transformed<IQuoteData, {

        tourRevision: Transformed<ITourRevisionData, {
            pcTour?: Transformed<IPCTourData, {
                package: Transformed<IPCTourPackageData>;
            }>
        }>;

        combinationRevision?: Transformed<ICombinationRevisionData, {
            pcCombination?: Transformed<IPCCombinationData>;
        }>;
    }>>;
}>;

function populateTransformed(rs: Response<transformed>): Array<Action> {
    const { data, includes } = rs.body;
    const actions = [
        OrmStory.populateOpportunities([data])
    ];
    if (!includes) {
        return actions;
    }
    const tourRevisions: { [k: string]: ITourRevisionData } = { };
    const combinationRevisions: { [k: string]: ICombinationRevisionData } = { };
    const pcPackages: { [k: string]: IPCTourPackageData } = { };
    const pcTours: { [k: number]: IPCTourData } = { };
    const pcCombinations: { [k: string]: IPCCombinationData } = { };
    let documents = [];
    let consents = [];
    let travelWiseDocuments = [];
    if ('pipeline' in includes && includes.pipeline.data) {
        actions.push(OrmStory.populatePipelineLevels([includes.pipeline.data]));
    }
    if ('assigned' in includes && includes.assigned.data) {
        actions.push(OrmStory.populateUsers([includes.assigned.data]));
    }
    if ('package' in includes && includes.package.data) {
        pcPackages[includes.package.data.id] = includes.package.data;
    }
    if ('tourRevision' in includes && includes.tourRevision.data) {
        tourRevisions[includes.tourRevision.data.id] = includes.tourRevision.data;
        const rIncludes = includes.tourRevision.includes || { };
        if ('destinationRevisions' in rIncludes && rIncludes.destinationRevisions.length) {
            actions.push(OrmStory.populateDestinationRevisions(rIncludes.destinationRevisions.map(r => r.data)));
        }
        if ('destinationToTourRevisionMappings' in rIncludes && rIncludes.destinationToTourRevisionMappings.length) {
            actions.push(OrmStory.populateDestinationToTourRevisionMappings(rIncludes.destinationToTourRevisionMappings.map(r => r.data)));
        }
        if ('pcTour' in rIncludes && rIncludes.pcTour.data) {
            pcTours[rIncludes.pcTour.data.id] = rIncludes.pcTour.data;
        }
    }
    if ('combinationRevision' in includes && includes.combinationRevision.data) {
        combinationRevisions[includes.combinationRevision.data.id] = includes.combinationRevision.data;
        if ('pcCombination' in includes.combinationRevision.includes && includes.combinationRevision.includes.pcCombination.data) {
            const pcCombinationData = includes.combinationRevision.includes.pcCombination.data;
            pcCombinations[pcCombinationData.id] = pcCombinationData;
        }
    }

    if ('emails' in includes && includes.emails.length) {
        const includesEmails = includes.emails;
        if ('parkedEmails' in includes && includes.parkedEmails.data !== null) {
            const parkedEmails = includes.parkedEmails;
            if (data.pipelineId === PipelineLevel.PIPELINE_LEVEL_PHONE_CALL) {
                includesEmails.unshift(parkedEmails);
            }
        }
        actions.push(OrmStory.populateOpportunityEmailMessageLogs(includesEmails.map(r => r.data)));
    } else {
        if ('parkedEmails' in includes && includes.parkedEmails.data !== null) {
            const parkedEmails = includes.parkedEmails;
            if (data.pipelineId === PipelineLevel.PIPELINE_LEVEL_PHONE_CALL) {
                actions.push(OrmStory.populateOpportunityEmailMessageLogs([parkedEmails.data]));
            }
        }
    }
    if ('logs' in includes && includes.logs.length) {
        actions.push(OrmStory.populateOpportunityLogs(includes.logs.map(r => r.data)));
    }
    if ('documents' in includes && 'documentMappings' in includes && includes.documents.length) {
        documents = documents.concat(includes.documents.map(r => r.data));
        actions.push(OrmStory.populateDocumentOpportunityMappings(includes.documentMappings.map(r => r.data)));
    }
    if ('consents' in includes && includes.consents.length) {
        consents = consents.concat(includes.consents.map(r => r.data));
        actions.push(OrmStory.populateConsent(consents));
    }
    if ('consentsMappings' in includes && includes.consentsMappings.length) {
        actions.push(OrmStory.populateConsentOpportunityMappings(includes.consentsMappings.map(r => r.data)));
    }
    if ('travelWiseDocuments' in includes && 'documentTravelWiseMappings' in includes && includes.travelWiseDocuments.length) {
        travelWiseDocuments = travelWiseDocuments.concat(includes.travelWiseDocuments.map(r => r.data));
        actions.push(OrmStory.populateDocumentTravelWiseOpportunityMappings(includes.documentTravelWiseMappings.map(r => r.data)));
    }
    if ('tourItinerary' in includes) {
        actions.push(OrmStory.populateItineraries([includes.tourItinerary.data]));
        const rIncludes = includes.tourItinerary.includes;
        if (rIncludes) {
            if ('prices' in rIncludes && rIncludes.prices.length) {
                actions.push(OrmStory.populateItineraryPrices(rIncludes.prices.map(r => r.data)));
            }
        }
    }
    if ('combinationItinerary' in includes && includes.combinationItinerary.data) {
        actions.push(OrmStory.populateItineraries([includes.combinationItinerary.data]));
        const rIncludes = includes.combinationItinerary.includes;
        if (rIncludes) {
            if ('prices' in rIncludes && rIncludes.prices.length) {
                actions.push(OrmStory.populateItineraryPrices(rIncludes.prices.map(r => r.data)));
            }
        }
    }
    if ('customer' in includes) {
        actions.push(OrmStory.populateCustomers([includes.customer.data]));
        const rIncludes = includes.customer.includes;
        if (rIncludes) {
            if ('logs' in rIncludes && rIncludes.logs.length) {
                actions.push(OrmStory.populateCustomerLogs(rIncludes.logs.map(r => r.data)));
            }
            if ('emails' in rIncludes && rIncludes.emails.length) {
                actions.push(OrmStory.populateCustomerEmailMessageLogs(rIncludes.emails.map(r => r.data)));
            }
            if ('country' in rIncludes && rIncludes.country && rIncludes.country.data) {
                actions.push(OrmStory.populateCountries([rIncludes.country.data]));
                actions.push(OpportunityStory.updateCountryEditPage([rIncludes.country.data.id]));
            }
            if ('documents' in rIncludes && 'documentMappings' in rIncludes && rIncludes.documents.length) {
                documents = documents.concat(rIncludes.documents.map(r => r.data));
                actions.push(OrmStory.populateDocumentCustomerMappings(rIncludes.documentMappings.map(r => r.data)));
            }
        }
    }
    if ('offlineBooking' in includes && includes.offlineBooking.data) {
        actions.push(OrmStory.populateOfflineBookings([includes.offlineBooking.data]));
        const rIncludes = includes.offlineBooking.includes;
        if (rIncludes) {
            if ('documents' in rIncludes && 'documentMappings' in rIncludes && rIncludes.documents.length) {
                documents = documents.concat(rIncludes.documents.map(r => r.data));
                actions.push(OrmStory.populateDocumentOfflineBookingMappings(rIncludes.documentMappings.map(r => r.data)));
            }
        }
    }
    if ('offlineBookingPassengers' in includes && includes.offlineBookingPassengers.length) {
        actions.push(OrmStory.populateOfflineBookingPassengers(includes.offlineBookingPassengers.map(r => r.data)));
    }
    if ('offlineBookingPayments' in includes && includes.offlineBookingPayments.length) {
        actions.push(OrmStory.populateOfflineBookingPayments(includes.offlineBookingPayments.map(r => r.data)));
    }
    if ('vamoos' in includes && includes.vamoos.data) {
        actions.push(OrmStory.populateVamoos([includes.vamoos.data]));
    }
    if ('delivery' in includes && includes.delivery.data) {
        actions.push(OrmStory.populateDeliveries([includes.delivery.data]));

        const tourItinerary = transformItineraryToValue(includes.delivery.includes.tourItinerary);
        const combinationItinerary = transformItineraryToValue(includes.delivery.includes.combinationItinerary);

        [tourItinerary, combinationItinerary].forEach(itinerary => {
            if (itinerary === null) {
                return;
            }
            actions.push(
                OrmStory.populateItineraries([itinerary]),
                OrmStory.populateItineraryDays(itinerary.days),
            );
            itinerary.days.forEach(day => {
                actions.push(
                    OrmStory.populateTwProducts([day.productId]),
                    OrmStory.populateTwContracts([day.contractId]),
                    OrmStory.populateTwOptions([day.optionId]),
                );
            });
        });
    }
    if ('offers' in includes && includes.offers.length) {
        actions.push(OrmStory.populateQuotes(includes.offers.map(r => r.data)));
        includes.offers.map(r => r.includes).filter(r => r).forEach(t => {
            const { tourRevision } = t;
            if (!tourRevision || !tourRevision.data) {
                return;
            }
            tourRevisions[tourRevision.data.id] = tourRevision.data;
            const { pcTour = null } = tourRevision.includes;
            if (!pcTour || !pcTour.data) {
                return;
            }
            pcTours[pcTour.data.id] = pcTour.data;
            const pkg = pcTour.includes.package.data;
            pcPackages[pkg.id] = pkg;
        });
        includes.offers.map(r => r.includes).filter(r => r).forEach(t => {
            const { combinationRevision } = t;
            if (!combinationRevision || !combinationRevision.data) {
                return;
            }
            combinationRevisions[combinationRevision.data.id] = combinationRevision.data;
            const { pcCombination } = combinationRevision.includes;
            if (!pcCombination || !pcCombination.data) {
                return;
            }
            pcCombinations[pcCombination.data.id] = pcCombination.data;
        });
    }
    if (Object.values(pcPackages).length) {
        actions.push(OrmStory.populatePcTourPackages(Object.values(pcPackages)));
    }
    if (Object.values(pcTours).length) {
        actions.push(OrmStory.populatePcTours(Object.values(pcTours)));
    }
    if (Object.values(pcCombinations).length) {
        actions.push(OrmStory.populatePcCombinations(Object.values(pcCombinations)));
    }
    if (Object.values(tourRevisions).length) {
        actions.push(OrmStory.populateTourRevisions(Object.values(tourRevisions)));
    }
    if (Object.values(combinationRevisions).length) {
        actions.push(OrmStory.populateCombinationRevisions(Object.values(combinationRevisions)));
    }
    if (documents.length) {
        actions.push(OrmStory.populateDocuments(documents));
    }
    if (travelWiseDocuments.length) {
        actions.push(OrmStory.populateTravelWiseDocuments(travelWiseDocuments));
    }

    return actions;
}

type searchRsItem = Partial<IOpportunityData> & {
    customerId: ICustomerData['id'];
    customerName: ICustomerData['name'];
    customerSurname: ICustomerData['surname'];
    customerEmail: ICustomerData['email'];
    assignedId: IUserData['id'];
    assignedUsername: IUserData['username'];
    packageId: IPCTourPackageData['id'];
    packageName: IPCTourPackageData['name'];
    packageCode: IPCTourPackageData['code'];
    pipelineId: IPipelineLevelData['id'];
    pipelineName: IPipelineLevelData['name'];
};

export const opportunityModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http, instanceManager }: IEpicDi) => merge(
    action.pipe(
        ofType(OpportunityStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.opportunityModule.get('searchPageInitialized')),
        switchMap(() => {
            // Search query is the same for all requests
            const allSearch: DataSearchRqData = {
                where: { showDeactivated: false },
                pagination: new Pagination().all()
            };

            return concat(of(OpportunityStory.updateSearchPageInitState(false)), http.post('enquire-data/search-assigned', allSearch).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    OrmStory.populateUsers(rs.body.results),
                    OpportunityStory.updateAssignedSearchPage(rs.body.results.map(r => r.id)),
                    // Reset all data
                    OpportunityStory.updateFormSearchPage({ }),
                    OpportunityStory.updateSearchPackageLockOnSearchPage(null),
                    OpportunityStory.updatePackagesSearchPage([]),
                    OpportunityStory.updateSearchPipelineLevelLockOnSearchPage(null),
                    OpportunityStory.updatePipelineLevelsSearchPage([]),
                    OpportunityStory.updatePipelineLevelsSearchPage([]),
                    OpportunityStory.importRecordsOnSearchPage([]),
                    OpportunityStory.updatePaginationOnSearchPage(null),
                    OpportunityStory.updateOrderOnSearchPage(null),
                    OpportunityStory.updateSearchLockOnSearchPage(null),
                    OpportunityStory.updateCollapsedSearchPage(null),
                    // Unlock page
                    OpportunityStory.updateSearchPageInitState(true),
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.SEARCH_PAGE_PACKAGE_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    codeName: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'codeName', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchPackageLockOnSearchPage(true)), http.post('enquire-data/search-packages', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPCTourPackageData>>>) => [
                    OpportunityStory.updateSearchPackageLockOnSearchPage(false),
                    OrmStory.populatePcTourPackages(rs.body.results),
                    OpportunityStory.updatePackagesSearchPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.SEARCH_PAGE_PIPELINE_LEVEL_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'level', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchPipelineLevelLockOnSearchPage(true)), http.post('enquire-data/search-pipelines', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPipelineLevelData>>>) => [
                    OpportunityStory.updateSearchPipelineLevelLockOnSearchPage(false),
                    OrmStory.populatePipelineLevels(rs.body.results),
                    OpportunityStory.updatePipelineLevelsSearchPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.opportunityModule;
            const level: number | null = module.getIn(['searchPageForm', 'pipelineLevel'], false);
            const group = module.getIn(['searchPageForm', 'pipelineLevelGroups']);
            const rq: DataSearchRqData = {
                where: {
                    brands: module.getIn(['searchPageForm', 'brands']),
                    locales: module.getIn(['searchPageForm', 'locales']),
                    enqId: module.getIn(['searchPageForm', 'enqId'], null),
                    customer: module.getIn(['searchPageForm', 'customer'], null),
                    assigned: module.getIn(['searchPageForm', 'assigned'], null),
                    package: module.getIn(['searchPageForm', 'package'], null),
                    pax: module.getIn(['searchPageForm', 'pax'], null),
                    sent: module.getIn(['searchPageForm', 'sent']),
                    pingBack: module.getIn(['searchPageForm', 'pingBack']),
                    pipelineLevels: level ? [level] : PIPELINE_LEVEL_PRESETS_LEVELS.get(group),
                    createdAt: module.getIn(['searchPageForm', 'createdAt'], null),
                    flightAt: module.getIn(['searchPageForm', 'flightAt'], null),
                    followUpAt: module.getIn(['searchPageForm', 'followUpAt'], null),
                    sansOffers: module.getIn(['searchPageForm', 'sansOffers'], false),
                    phone: module.getIn(['searchPageForm', 'phone']),
                    interest: module.getIn(['searchPageForm', 'interest']),
                },
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(OpportunityStory.updateSearchLockOnSearchPage(true)), http.post('enquire-data/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<searchRsItem>>>) => [
                    OrmStory.populateCustomers(rs.body.results.map(r => ({ id: r.customerId, name: r.customerName, surname: r.customerSurname, email: r.customerEmail }))),
                    OrmStory.populateUsers(rs.body.results.filter(r => r.assignedId).map(r => ({ id: r.assignedId, username: r.assignedUsername }))),
                    OrmStory.populatePcTourPackages(rs.body.results.map(r => ({ id: r.packageId, name: r.packageName, code: r.packageCode }))),
                    OrmStory.populatePipelineLevels(rs.body.results.map(r => ({ id: r.pipelineId, name: r.pipelineName }))),
                    OrmStory.populateOpportunities(rs.body.results.map(r => ({
                        id: r.id,
                        brand: r.brand,
                        brandLabel: r.brandLabel,
                        locale: r.locale,
                        createdAt: r.createdAt,
                        updatedAt: r.updatedAt,
                        flightAt: r.flightAt,
                        followUpAt: r.followUpAt,
                        leadScore: r.leadScore,
                        interest: r.interest,
                        adults: r.adults,
                        children: r.children,
                        sent: r.sent,
                        customerId: r.customerId,
                        assignedTo: r.assignedId,
                        packageId: r.packageId,
                        pipelineId: r.pipelineId,
                        levelWonExists: r.levelWonExists,
                        isUsedCustomPipeline: r.isUsedCustomPipeline,
                        isUsedVamoos: r.isUsedVamoos,
                        vamoosAppId: r.vamoosAppId,
                        vamoosUser: r.vamoosUser,
                        vamoosReferenceCode: r.vamoosReferenceCode
                    }))),
                    OpportunityStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    OpportunityStory.updatePaginationOnSearchPage(rs.body.pagination),
                    OpportunityStory.updateOrderOnSearchPage(rs.body.order),
                    OpportunityStory.updateSearchLockOnSearchPage(false),
                ])
            ));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_DRY_RUN),
        filter(({ payload }: Action<IOpportunityData['id']>) => state.value.opportunityModule.get('editPageRecordId') !== payload),
        mergeMap(({ payload }: Action<IOpportunityData['id']>) => http.get(`enquire-data/${ payload }`).pipe(
            dispatchActions((rs: Response<transformed>) => {
                    const delivery = 'delivery' in rs.body.includes ? rs.body.includes.delivery.data : null;

                    if (delivery !== null && 'tourItinerary' in rs.body.includes.delivery.includes) {
                        delivery.tourItinerary = transformItineraryToValue(rs.body.includes.delivery.includes.tourItinerary);
                    }

                    if (delivery !== null && 'combinationItinerary' in rs.body.includes.delivery.includes) {
                        delivery.combinationItinerary = transformItineraryToValue(rs.body.includes.delivery.includes.combinationItinerary);
                    }

                    return [
                        DashboardStory.updateLastOpportunitiesOnSearchPage(payload),
                        OpportunityStory.updateOpportunityIdEditPage(payload),
                        OpportunityStory.updateDeliveryMetaEditPage(delivery && !delivery.deleted ? delivery : null),
                        OpportunityStory.updateStackViewSegmentsEditPage(OPPORTUNITY_EDIT_SEGMENTS.toJS()),
                        ...populateTransformed(rs),
                        OpportunityStory.updateFormEditPage({ }),
                        OpportunityStory.updateMergerInitStateOnEditPage(false),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(OpportunityViewStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['ao', 'opportunities']),
                ],
            ),
        )),
    ),
    action.pipe(
        ofType(OpportunityStory.RELOAD_FLIGHTS_ON_VAMOOS),
        mergeMap(({ payload }: Action<IOpportunityData['id']>) => http.get(`enquire-data/vamoos-flights-reload/${ payload }`).pipe(
            dispatchActions((rs: Response<transformed>) => {

                    return [
                        OrmStory.updateVamoos([rs.body.data]),
                        OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                        OpportunityStory.updateVamoosFlightsEditPage(false),
                    ];
                },
                () => [
                    SystemStory.stopNavigate(OpportunityViewStateResolver.STOP_EVENT_NAME),
                    SystemStory.navigate(['ao', 'opportunities']),
                ],
            ),
        )),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_USER_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchUserLockEditPage(true)), http.post('enquire-data/search-users', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IUserData>>>) => [
                    OpportunityStory.updateSearchUserLockEditPage(false),
                    OrmStory.populateUsers(rs.body.results),
                    OpportunityStory.updateUsersEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_PIPELINE_LEVEL_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'level', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchPipelineLevelLockEditPage(true)), http.post('enquire-data/search-pipelines', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPipelineLevelData>>>) => [
                    OpportunityStory.updateSearchPipelineLevelLockEditPage(false),
                    OrmStory.populatePipelineLevels(rs.body.results),
                    OpportunityStory.updatePipelineLevelsEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_COUNTRY_SEARCH),
        switchMap(({ payload }: Action<string>) => {
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    deleted: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchCountryLockEditPage(true)), http.post('enquire-data/search-countries', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<ICountryData>>>) => [
                    OpportunityStory.updateSearchCountryLockEditPage(false),
                    OrmStory.populateCountries(rs.body.results),
                    OpportunityStory.updateCountryEditPage(rs.body.results.map(r => r.id))
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_UPLOAD_DOCUMENTS),
        switchMap(({ payload }: Action<Array<File>>) => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');

            return concat(of(OpportunityStory.updateUploadDocumentsLockEditPage(true)), zip(...payload.map(readToBase64)).pipe(
                    // switchMap((results: Array<IFileData>) => concat(http.post('travel-wise-data', { documents: [], ...{ twOrderId: 45321 } })).pipe(
                    // switchMap((results: Array<IFileData>) => concat(http.post('travel-wise-data', { documents: results, ...{ twOrderId: 45321 } })).pipe(
                    switchMap((results: Array<IFileData>) => concat(http.post('document-enquire-data', { documents: results, ...{ enqId: id } })).pipe(
                    switchMap((rs: viewDocumentRs) => {
                        const collection: Array<IDocumentData> = rs.body.documents;
                        const actions = [
                            OpportunityStory.updateUploadDocumentsLockEditPage(false)
                        ];
                        if (!collection.length) {
                            return actions;
                        }

                        return actions.concat([
                            OrmStory.populateDocuments(collection),
                            OrmStory.populateDocumentOpportunityMappings(collection.map(r => ({ documentId: r.id, enquireDataId: id }))),
                            OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                            SystemStory.showNotification(SystemNotification.success('Success', `Document ${ collection.length > 1 ? 's' : ''} uploaded`)),
                        ]);
                    })
                )),
            ));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_REMOVE_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(OpportunityStory.updatePickedDocumentEditPage(payload)), http.delete(`document-enquire-data/${ payload }`).pipe(
            dispatchActions(() => [
                OpportunityStory.updatePickedDocumentEditPage(null),
                OrmStory.dropDocuments([payload]),
                OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                SystemStory.showNotification(SystemNotification.success('Success', 'Document removed'))
            ])
        ))),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_REMOVE_EMAIL),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');

            return http.delete(`email-parked/${ payload }`).pipe(
                dispatchActions(
                    (rs:  Response<any>) => [
                        OrmStory.dropLogs([payload]),
                        OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                        SystemStory.navigate(['ao', 'opportunities', id]),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Email removed'))
                     ]
                )
            );
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_DOWNLOAD_DOCUMENT),
        mergeMap(({ payload }: Action<string>) => concat(of(OpportunityStory.updatePickedDocumentEditPage(payload)), http.get(`document-enquire-data/download/${ payload }`, { observe: 'response', responseType: 'text' }).pipe(
            mergeMap((rs: HttpResponse<string>) => {
                tryToSaveFile(rs);

                return of(OpportunityStory.updatePickedDocumentEditPage(null));
            })
        ))),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_UPGRADE_TO_DELIVERY),
        switchMap(() => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');

            return concat(of(OpportunityStory.updateDeliverySaveLockEditPage(true)), http.get(`enquire-data/delivery/${ id }`).pipe(dispatchActions(
                (rs: Response<Transformed<IDeliveryData, { combinationItinerary?: TransformedItinerary, tourItinerary?: TransformedItinerary }>>) => {
                    const tourItinerary = 'tourItinerary' in rs.body.includes ? transformItineraryToValue(rs.body.includes.tourItinerary) : null;
                    const combinationItinerary = 'combinationItinerary' in rs.body.includes ? transformItineraryToValue(rs.body.includes.combinationItinerary) : null;

                    return [
                        OrmStory.populatePipelineLevels([(<Transformed<IPipelineLevelData>>rs.body.meta.pipeline).data]),
                        OpportunityStory.updateDeliveryMetaEditPage({ ...rs.body.data, tourItinerary, combinationItinerary }),
                        OpportunityStory.updateStackViewSegmentsEditPage({ delivery: true }),
                        OpportunityStory.updateDeliverySaveLockEditPage(false),
                    ];
                },
                () => [
                    OpportunityStory.updateDeliverySaveLockEditPage(false),
                ]
            )));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_REMOVE_LOGS_COMMENT),
        mergeMap(({ payload }: Action<number>) => concat(of(OpportunityStory.updateDeliverySaveLockEditPage(true)), http.delete(`enquire-data/log/${payload}`).pipe(
            dispatchActions((rs: Response<transformed>) => {

                return populateTransformed(rs).concat([
                    OpportunityStory.updateDeliverySaveLockEditPage(false),
                    OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                    RootStory.resetForm('log'),
                    SystemStory.showNotification(SystemNotification.success('Success', 'Comment removed'))
                ]);
            })
        ))),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_REMOVE_DELIVERY),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');
            const r = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id)).relatedDelivery.first();
            if (!r || r.deleted) {
                return [
                    OpportunityStory.updateDeliveryMetaEditPage(null),
                    OpportunityStory.updateStackViewSegmentsEditPage({ delivery: false }),
                    SystemStory.showNotification(SystemNotification.success('Success', 'Delivery removed')),
                ];
            }
            const rq: any = {
                enquire: {
                    id
                },
                delivery: {
                    deleted: true
                },
                changesDescription: module.getIn(['editPageForm', 'log', 'message']),
            };

            return concat(of(OpportunityStory.updateDeliverySaveLockEditPage(true)), http.post(`enquire-data/toggle-delivery-archive`, rq).pipe(dispatchActions(
                (rs: Response<transformed>) => [
                    ...populateTransformed(rs),
                    OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                    RootStory.resetForm('request'),
                    RootStory.resetForm('log'),
                    OpportunityStory.updateDeliveryMetaEditPage(null),
                    OpportunityStory.updateStackViewSegmentsEditPage({ delivery: false }),
                    OpportunityStory.updateDeliverySaveLockEditPage(false),
                    SystemStory.showNotification(SystemNotification.success('Success', 'Delivery removed')),
                ],
                () => [
                    OpportunityStory.updateDeliverySaveLockEditPage(false),
                ]
            )));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_TOGGLE_ARCHIVE),
        mergeMap(({ payload }) => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');
            const rq: any = {
                archived: payload,
                changesDescription: module.getIn(['editPageForm', 'log', 'message']),
            };

            return concat(of(OpportunityStory.updateSaveLockEditPage(true)), http.post(`enquire-data/toggle-archive/${ id }`, rq).pipe(
                dispatchActions((rs: Response<transformed>) => [
                    OpportunityStory.updateSaveLockEditPage(false),
                    ...populateTransformed(rs),
                    OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                    RootStory.resetForm('log'),
                    SystemStory.showNotification(SystemNotification.success('Success', 'Opportunity updated')),
                ])));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_SUBMIT),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, id);

            return concat(of(OpportunityStory.updateSaveLockEditPage(true)), http.post(`enquire-data/check-customer`, { id: opportunity.customerId.id, email: module.getIn(['editPageForm', 'customer', 'email']) }).pipe(switchMap(
                (response: Response<Transformed<ICustomerData> | null>) => {
                    if (!response.success) {
                        return [
                            OpportunityStory.updateSaveLockEditPage(false),
                        ];
                    }
                    if (response.body) {
                        return [
                            OrmStory.populateCustomers([response.body.data]),
                            OpportunityStory.updateCustomerMergerIdOnEditPage(response.body.data.id),
                            OpportunityStory.updateSaveLockEditPage(false),
                        ];
                    }
                    const form = module.get('editPageForm');
                    const formData = form.get('request').toJS();
                    if (formData.children === null) {
                        formData.children = 0;
                    }

                    const rq: { [key: string]: any } = {
                        enquire: {
                            ...formData,
                            updatedAt: opportunity.updatedAt,
                            assignedTo: form.getIn(['request', 'assigned', 'id']),
                            pipelineId: form.getIn(['request', 'pipelineLevel', 'id']),
                        },
                        customer: form.get('customer').toJS(),
                        changesDescription: form.getIn(['log', 'message'])
                    };
                    // Checks one of required field
                    if (form.has('offlineBooking')) {
                        rq.offlineBooking = form.get('offlineBooking').toJS();
                        rq.offlineBookingPassengers = form.get('offlineBookingPassengers').toJS();
                    }
                    if (form.has('delivery')) {
                        rq.delivery = {
                            ...form.get('delivery').toJS(),
                            deleted: false, // immedently restore delivery if removed
                        };
                    }

                    return http.put(`enquire-data/${ id }`, rq).pipe(dispatchActions(
                        (rs: Response<transformed>) => {
                            const delivery = 'delivery' in rs.body.includes ? rs.body.includes.delivery.data : null;

                            if (delivery !== null && 'tourItinerary' in rs.body.includes.delivery.includes) {
                                delivery.tourItinerary = transformItineraryToValue(rs.body.includes.delivery.includes.tourItinerary);
                            }

                            if (delivery !== null && 'combinationItinerary' in rs.body.includes.delivery.includes) {
                                delivery.combinationItinerary = transformItineraryToValue(rs.body.includes.delivery.includes.combinationItinerary);
                            }

                            return populateTransformed(rs).concat([
                                OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                                RootStory.resetForm('request'),
                                RootStory.resetForm('log'),
                                OpportunityStory.updateDeliveryMetaEditPage(delivery && !delivery.deleted ? delivery : null),
                                // Reset customer state
                                OpportunityStory.updateSaveLockEditPage(false),
                                CustomerStory.updateCustomerIdEditPage(null),
                                QuoteStory.updateOpportunityIdEditPage(null),
                                QuoteStory.updateResolverIdEditPage(null),
                                SystemStory.showNotification(SystemNotification.success('Success', 'Opportunity saved'))
                            ]);
                        },
                        () => [
                            OpportunityStory.updateSaveLockEditPage(false),
                        ]
                    ));
                }
            )));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_CUSTOMER_MERGER_SUBMIT),
        mergeMap(({ payload }: Action<{ from: ICustomerData['id'], to: ICustomerData['id'] }>) => {
            const module = state.value.opportunityModule;

            return concat(of(OpportunityStory.updateSaveLockEditPage(true)), http.post(`enquire-data/merge-customer`, { ...payload, changesDescription: module.getIn(['editPageForm', 'log', 'message']) }).pipe(dispatchActions(
                (rs: Response<transformed['includes']['customer']>) => {
                    const opportunities = <unknown>findById<Customer>(orm.session(state.value.orm), Customer, payload.from).relatedOpportunities.all().toRefArray();

                    return [
                        OrmStory.dropCustomers([payload.from]),
                        OrmStory.populateCustomers([rs.body.data]),
                        OrmStory.populateOpportunities((<Array<Partial<IOpportunityData>>>opportunities).map(r => ({ ...r, customerId: rs.body.data.id }))),
                        OrmStory.populateCustomerLogs(rs.body.includes.logs.map(r => r.data)),
                        OrmStory.populateCustomerEmailMessageLogs(rs.body.includes.emails.map(r => r.data)),
                        OrmStory.populateCountries([rs.body.includes.country.data]),
                        OrmStory.populateDocumentCustomerMappings(rs.body.includes.documentMappings.map(r => r.data)),
                        OpportunityStory.updateCountryEditPage([rs.body.includes.country.data.id]),
                        OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                        RootStory.resetForm('person'),
                        RootStory.resetForm('log'),
                        OpportunityStory.updateSaveLockEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', 'Customer merged'))
                    ];
                },
                () => [
                    OpportunityStory.updateSaveLockEditPage(false),
                ]
            )));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_UPDATE_LEAD_SCOPE),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');
            const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(id));

            return concat(of(OpportunityStory.updateSaveLockEditPage(true)), http.post(`enquire-data/calc-lead-score/${ id }`, null).pipe(dispatchActions(
                (rs: Response<transformed>) => {
                    const msg = opportunity.leadScore === rs.body.data.leadScore ? 'Lead point scope not changed' : `Lead point scope changed to ${ rs.body.data.leadScore }`;
                    const actions = [
                        OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                        RootStory.resetForm('log'),
                    ];
                    actions.push(OpportunityStory.updateSaveLockEditPage(false));
                    actions.push(SystemStory.showNotification(SystemNotification.success('Success', msg)));

                    return populateTransformed(rs).concat(actions);
                },
                () => [
                    OpportunityStory.updateSaveLockEditPage(false),
                ]
            )));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_MERGER_OPEN),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const id: any = module.get('editPageRecordId');

            if (module.get('editPageMergerInitialized')) {
                return of(OpportunityStory.updateMergerOpenedOnEditPage(true));
            }

            return http.get(`enquire-data/same`, { params: { id } }).pipe(
                dispatchActions(
                    (rs: Response<Array<transformed>>) => {
                        const opportunities: Array<IOpportunityData> = [];
                        const packages: { [k: string]: IPCTourPackageData } = { };
                        const combinationRevisions: { [k: string]: ICombinationRevisionData } = { };
                        const actions = [
                            OrmStory.populateOpportunities(opportunities),
                            OrmStory.populatePcTourPackages(Object.values(packages))
                        ];
                        rs.body.forEach(d => {
                            const { data, includes } = d;
                            opportunities.push(data);
                            if ('package' in includes && includes.package.data) {
                                packages[includes.package.data.id] = includes.package.data;
                            }
                            if ('combinationRevision' in includes && includes.combinationRevision.data) {
                                combinationRevisions[includes.combinationRevision.data.id] = includes.combinationRevision.data;
                            }
                        });
                        if (Object.values(packages).length) {
                            actions.push(OrmStory.populatePcTourPackages(Object.values(packages)));
                        }
                        if (Object.values(combinationRevisions).length) {
                            actions.push(OrmStory.populateCombinationRevisions(Object.values(combinationRevisions)));
                        }

                        return actions.concat([
                            OpportunityStory.updateMergerOpportunitiesOnEditPage(opportunities.map(r => r.id)),
                            OpportunityStory.updateMergerSelectedOpportunitiesOnEditPage(opportunities.map(r => r.id)),
                            OpportunityStory.updateMergerSearchFormOnEditPage({ }),
                            OpportunityStory.updateMergerOpenedOnEditPage(true),
                            OpportunityStory.updateMergerInitStateOnEditPage(true)
                        ]);
                    },
                    () => [
                        OpportunityStory.updateMergerOpenedOnEditPage(false),
                        OpportunityStory.updateMergerInitStateOnEditPage(false)
                    ])
            );
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_MERGER_UPDATE_SEARCH_FORM_SUBMIT),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const currId = module.get('editPageRecordId');
            const id = parseInt(module.getIn(['editPageMergerSearchForm', 'enqId']));

            if (currId === id) {
                return of(SystemStory.showNotification(SystemNotification.error('Error', 'You can\'t add current opportunity to this list')));
            }

            if (module.get('editPageMergerSelectedOpportunities').includes(id)) {
                return of(SystemStory.showNotification(SystemNotification.error('Error', 'This opportunity already added')));
            }

            return concat(of(OpportunityStory.updateMergerSearchLockOnEditPage(true)), http.get(`enquire-data/same-data`, { params: { id: String(id) } }).pipe(
                dispatchActions(
                    (rs: Response<transformed>) => [
                        ...populateTransformed(rs),
                        OpportunityStory.addMergerOpportunityOnEditPage(rs.body.data.id),
                        OpportunityStory.addMergerSelectedOpportunitiesOnEditPage(rs.body.data.id),
                        RootStory.resetForm('merger'),
                        OpportunityStory.updateMergerSearchLockOnEditPage(false)
                    ],
                    () => [
                        OpportunityStory.updateMergerSearchLockOnEditPage(false)
                    ])
            ));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_MERGER_FORGET),
        mergeMap(() => {
            const module = state.value.opportunityModule;
            const queue = concat(
                of(OpportunityStory.updateMergerSearchLockOnEditPage(true)),
                of(OpportunityStory.updateMergerForgetLockOnEditPage(true))
            );
            const ids = module.get('editPageMergerSelectedOpportunities').toArray();

            return concat(queue, http.post(`enquire-data/bulk-archive`, { ids }).pipe(
                dispatchActions(
                    (rs: Response<number>) => [
                        RootStory.resetForm('merger'),
                        OrmStory.updateOpportunities(ids.map(id => ({ id, archived: true }))),
                        OpportunityStory.updateMergerOpportunitiesOnEditPage([]),
                        OpportunityStory.updateMergerSelectedOpportunitiesOnEditPage([]),
                        OpportunityStory.updateMergerOpenedOnEditPage(false),
                        OpportunityStory.updateMergerForgetLockOnEditPage(false),
                        OpportunityStory.updateMergerSearchLockOnEditPage(false),
                        OpportunityStory.updateMergerOpenedOnEditPage(false),
                        SystemStory.showNotification(SystemNotification.success('Success', `"${ rs.body }" opportunities merged`)),
                    ],
                    () => [
                        OpportunityStory.updateMergerForgetLockOnEditPage(false),
                        OpportunityStory.updateMergerSearchLockOnEditPage(false)
                    ]
                )));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.SEND_EMAIL_SEARCH_TEMPLATES),
        switchMap(({ payload }: Action<string>) => {
            const module = state.value.opportunityModule;
            const { brand, locale } = module.get('sendEmailTemplateForm').toJS();
            const rq: DataSearchRqData = {
                where: {
                    name: payload || null,
                    brand,
                    locale,
                    showDeactivated: false
                },
                order: new Order({ by: 'name', isReverse: false })
            };

            return concat(of(OpportunityStory.sendEmailUpdateTemplateLock(true)), http.post('enquire-data/search-email-templates', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IEmailTemplateData>>>) => [
                    OrmStory.populateEmailTemplates(rs.body.results),
                    OpportunityStory.sendEmailUpdateTemplates(rs.body.results.map(r => r.id)),
                    OpportunityStory.sendEmailUpdateTemplateLock(false)
                ])
            ));
        })
    ),
    action.pipe(
        ofType(OpportunityStory.SEND_EMAIL_GET_MESSAGE),
        switchMap(() => {
            const module = state.value.opportunityModule;
            const id = module.get('editPageRecordId');
            const data = module.get('sendEmailTemplateForm');
            const { brand, locale } = data.toJS();

            return concat(of(OpportunityStory.sendEmailUpdateMessageId(null)), http.post(`enquire-data/get-email-message/${ id }`, { templateId: data.getIn(['name', 'id']) }, instanceManager.buildHeaders([brand, locale])).pipe(
                dispatchActions((rs: viewSendEmailData) => {
                    const documentsMapping: Array<IDocumentEmailTemplateMappingData> = [];

                    rs.body.documents.forEach((document) => {
                        documentsMapping.push({
                            templateId: rs.body.message.templateId,
                            documentId: document.id
                        });
                    });

                    return [
                        OrmStory.populateEmailMessages([rs.body.message]),
                        OrmStory.populateDomainDetail([rs.body.domainDetail]),
                        OrmStory.populateDocuments(rs.body.documents),
                        OrmStory.populateUsers([rs.body.user]),
                        OrmStory.populateDocumentEmailTemplateMappings(documentsMapping),
                        OpportunityStory.sendEmailUpdateDocuments(rs.body.documents.map(r => r.id)),
                        OpportunityStory.sendEmailUpdateMessageId(rs.body.message.id)
                    ];
                })
            ));
        })
    ),
    // action.pipe(
    //     ofType(OpportunityStory.EDIT_PAGE_SEND_EMAIL_OPEN),
    //     mergeMap(() => {
    //         const module = state.value.opportunityModule;
    //         const opportunity = findById<Opportunity>(orm.session(state.value.orm), Opportunity, String(module.get('editPageRecordId')));
    //         const params = instanceManager.buildHeaders([opportunity.brand, opportunity.locale]);
    //
    //         return http.get(`enquire-data/show-send-email`, params).pipe(
    //             dispatchActions(
    //                 () => [
    //                     OpportunityStory.updateSendEmailOpenedOnEditPage(true),
    //                 ],
    //                 () => [
    //                     OpportunityStory.updateSendEmailOpenedOnEditPage(false),
    //                 ])
    //         );
    //     }),
    // ),
    action.pipe(
        ofType(OpportunityStory.SEND_EMAIL_SUBMIT),
        mergeMap(({ payload }: Action<Array<{ id: string, file: File }>>) => {
            const module = state.value.opportunityModule;
            const selectedIds = module.get('sendEmailDocumentIds');
            const stream = payload.length ? zip(...payload.filter(({ id }) => selectedIds.includes(id)).map(({ file }) => readToBase64(file))) : of([]);

            return concat(stream.pipe(mergeMap(files => {
                const documents = findById<EmailTemplate>(orm.session(state.value.orm), EmailTemplate, module.getIn(['sendEmailTemplateForm', 'name', 'id'])).relatedDocuments.all().toRefArray();
                const rq = {
                    ...module.get('sendEmailMessageForm').toJS(),
                    id: module.get('editPageRecordId'),
                    documents: documents.map(({ id }) => id).filter(id => selectedIds.includes(id)),
                    files,
                };
                const date = rq.notifiedAt !== undefined && rq.notifiedAt !== null ? rq.notifiedAt : null;

                return http.post('enquire-data/send-email', rq).pipe(dispatchActions((rs) => {
                    if (date) {
                        return [
                            // OrmStory.populateCustomerEmailMessageLogs(
                            // OrmStory.reloadModel(OrmManager.RELOAD_OPPORTUNITY),
                            SystemStory.showNotification(SystemNotification.warning('Warning', `Message created [${date}]. Email will be assigned to sending only after the opportunity saving.`)),
                        ];
                    }

                    return [SystemStory.showNotification(SystemNotification.success('Success', `Message sent.`)), ];
                }));
            })));
        }),
    ),
    action.pipe(
        ofType(OpportunityStory.EDIT_PAGE_DEFINITIONS_OPENED),
        switchMap(() => {
            const rq: DataSearchRqData = {
                where: {
                    name: null,
                    deleted: false
                },
                order: new Order({ by: 'level', isReverse: false })
            };

            return concat(of(OpportunityStory.updateSearchPipelineLevelLockOnSearchPage(true)), http.post('enquire-data/search-pipelines', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IPipelineLevelData>>>) => [
                    OpportunityStory.updateSearchPipelineLevelLockOnSearchPage(false),
                    OrmStory.populatePipelineLevels(rs.body.results),
                    PipelineStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                    OpportunityStory.updatePipelineLevelsSearchPage(rs.body.results.map(r => r.id)),
                    PipelineStory.updateDefinitionsOnSearchPage(true)

                ])
            ));
        })
    )
);
