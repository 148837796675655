import { IDomainDetailData } from '@aclass/admin/storage/models';
import { ISettingsModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'settings'
})
export class SettingsStory {

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static QUOTE_WORKING_HOURS_SEARCH_PAGE_SUBMIT: string;

    @Task() static QUOTE_WORKING_HOURS_EDIT_PAGE_DRY_RUN: string;

    @Task() static QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM: string;

    @Task() static QUOTE_WORKING_HOURS_EDIT_PAGE_SUBMIT: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static OFFICE_WORKING_HOURS_SEARCH_PAGE_SUBMIT: string;

    @Task() static OFFICE_WORKING_HOURS_EDIT_PAGE_DRY_RUN: string;

    @Task() static OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM: string;

    @Task() static OFFICE_WORKING_HOURS_EDIT_PAGE_SUBMIT: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_DRY_RUN: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static OFFER_VALIDITY_SEARCH_PAGE_SUBMIT: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_DRY_RUN: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_ADD_VALUES: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS_ACTIVE: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_REMOVE_VALUES_FORM: string;

    @Task() static OFFER_VALIDITY_EDIT_PAGE_SUBMIT: string;

    @Task() static ANONYMIZATION_EDIT_PAGE_DRY_RUN: string;

    @Task() static ANONYMIZATION_PAGE_UPDATE_INIT_STATE: string;

    @Task() static ANONYMIZATION_PAGE_UPDATE_PERIOD_VALUE: string;

    @Task() static ANONYMIZATION_PAGE_SUBMIT: string;

    @Task() static SEARCH_404_PAGE_DRY_RUN: string;

    @Task() static SEARCH_404_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_404_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_404_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_404_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_404_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_404_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_404_PAGE_SUBMIT: string;

    @Task() static EDIT_404_PAGE_DRY_RUN: string;

    @Task() static EDIT_404_PAGE_UPDATE_RECORD_ID: string;

    @Task() static EDIT_404_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_404_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static EDIT_404_PAGE_SUBMIT: string;

    @Task() static EDIT_DATE_FORMATTING_PAGE_UPDATE_INIT_STATE: string;

    @Task() static EDIT_DATE_FORMATTING_PAGE_DRY_RUN: string;

    @Task() static EDIT_DATE_FORMATTING_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static EDIT_DATE_FORMATTING_PAGE_SUBMIT: string;

    @Task() static EDIT_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE: string;

    @Task() static EDIT_PRICE_FORMATTING_PAGE_DRY_RUN: string;

    @Task() static EDIT_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static EDIT_PRICE_FORMATTING_PAGE_SUBMIT: string;

    @Task() static EDIT_PRICE_COLOR_RANGE_SUBMIT: string;

    @Task() static EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE: string;

    @Task() static EDIT_SYSTEM_PRICE_FORMATTING_PAGE_DRY_RUN: string;

    @Task() static EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE: string;

    @Task() static EDIT_SYSTEM_PRICE_FORMATTING_PAGE_SUBMIT: string;

    @Task() static OFFER_SEND_INTERVAL_EDIT_PAGE_DRY_RUN: string;

    @Task() static MARGIN_COLOR_RANGE_EDIT_PAGE_DRY_RUN: string;

    @Task() static OFFER_SEND_INTERVAL_PAGE_UPDATE_INIT_STATE: string;

    @Task() static MARGIN_COLOR_RANGE_PAGE_UPDATE_INIT_STATE: string;

    @Task() static OFFER_SEND_INTERVAL_PAGE_UPDATE_PERIOD_VALUE: string;

    @Task() static MARGIN_COLOR_RANGE_PAGE_UPDATE_FORM: string;

    @Task() static OFFER_SEND_INTERVAL_PAGE_SUBMIT: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_DRY_RUN: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_IMPORT_INSTANCES: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static MANUAL_HANDLING_SEARCH_PAGE_SUBMIT: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_UPDATE_FORM: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_DRY_RUN: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_UPDATE_RECORD_ID: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_UPDATE_TOURS: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_UPDATE_DESTINATIONS: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_UPDATE_OTHER_VALUES: string;

    @Task() static MANUAL_HANDLING_EDIT_PAGE_SUBMIT: string;

    @Task() static TOTAL_PAX_AMOUNT_EDIT_PAGE_DRY_RUN: string;

    @Task() static TOTAL_PAX_AMOUNT_PAGE_UPDATE_INIT_STATE: string;

    @Task() static TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_VALUE: string;

    @Task() static TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_OVER_VALUE: string;

    @Task() static DAYS_FOR_VAMOOS_FLIGHTS_SYNC_VALUE: string;

    @Task() static DAYS_TILL_VAMOOS_FLIGHTS_SYNC_VALUE: string;

    @Task() static DAYS_FOR_VAMOOS_DATES_SYNC_VALUE: string;

    @Task() static DAYS_TILL_VAMOOS_DATES_SYNC_VALUE: string;

    @Task() static VAMOOS_NOTIFY_BY_MAIL_VALUE: string;

    @Task() static VAMOOS_NOTIFY_BY_SMS_VALUE: string;

    @Task() static VAMOOS_NOTIFY_BY_APP_VALUE: string;

    @Task() static TOTAL_PAX_AMOUNT_PAGE_SUBMIT: string;

    @Task() static RETURNING_CUSTOMERS_EDIT_PAGE_DRY_RUN: string;

    @Task() static RETURNING_VAMOOS_EDIT_PAGE_DRY_RUN: string;

    @Task() static RETURNING_CUSTOMERS_PAGE_UPDATE_INIT_STATE: string;

    @Task() static RETURNING_VAMOOS_PAGE_UPDATE_INIT_STATE: string;

    @Task() static RETURNING_CUSTOMERS_PAGE_UPDATE_VALUE: string;

    @Task() static RETURNING_CUSTOMERS_PAGE_SUBMIT: string;

    @Task() static RETURNING_VAMOOS_PAGE_SUBMIT: string;

    static dryRunQuoteWorkingHoursSearchPage(): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN };
    }

    static updateQuoteWorkingHoursSearchPageInitState(payload: ISettingsModuleState['quoteWorkingHoursSearchPageInitialized']): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateSearchLockOnQuoteWorkingHoursSearchPage(payload: ISettingsModuleState['quoteWorkingHoursSearchPageLock']): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateCollapsedQuoteWorkingHoursSearchPage(payload: ISettingsModuleState['quoteWorkingHoursSearchPageCollapsed']): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static importRecordsOnQuoteWorkingHoursSearchPage(payload: Array<IDomainDetailData['id']>): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static importInstancesOnQuoteWorkingHoursSearchPage(payload: Array<string>): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES, payload };
    }

    static updateFormOnQuoteWorkingHoursSearchPage(payload): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static submitOnQuoteWorkingHoursSearchPage(): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_SUBMIT };
    }

    static dryRunQuoteWorkingHoursEditPage(payload: string): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdQuoteWorkingHoursEditPage(payload: ISettingsModuleState['quoteWorkingHoursEditPageRecordId']): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateSaveLockQuoteWorkingHoursEditPage(payload: ISettingsModuleState['quoteWorkingHoursSaveLock']): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateFormQuoteWorkingHoursEditPage(payload): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM, payload };
    }

    static submitQuoteWorkingHoursEditPage(): Action {
        return { type: SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_SUBMIT };
    }

    static dryRunOfficeWorkingHoursSearchPage(): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_DRY_RUN };
    }

    static updateOfficeWorkingHoursSearchPageInitState(payload: ISettingsModuleState['officeWorkingHoursSearchPageInitialized']): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateSearchLockOnOfficeWorkingHoursSearchPage(payload: ISettingsModuleState['officeWorkingHoursSearchPageLock']): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateCollapsedOfficeWorkingHoursSearchPage(payload: ISettingsModuleState['officeWorkingHoursSearchPageCollapsed']): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static importRecordsOnOfficeWorkingHoursSearchPage(payload: Array<IDomainDetailData['id']>): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static importInstancesOnOfficeWorkingHoursSearchPage(payload: Array<string>): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES, payload };
    }

    static updateFormOnOfficeWorkingHoursSearchPage(payload): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static submitOnOfficeWorkingHoursSearchPage(): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_SUBMIT };
    }

    static dryRunOfficeWorkingHoursEditPage(payload: string): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_DRY_RUN, payload };
    }

    static updateRecordIdOfficeWorkingHoursEditPage(payload: ISettingsModuleState['officeWorkingHoursEditPageRecordId']): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID, payload };
    }

    static updateSaveLockOfficeWorkingHoursEditPage(payload: ISettingsModuleState['officeWorkingHoursSaveLock']): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static updateFormOfficeWorkingHoursEditPage(payload): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM, payload };
    }

    static submitOfficeWorkingHoursEditPage(): Action {
        return { type: SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_SUBMIT };
    }

    static dryRunOfferValiditySearchPage(): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_DRY_RUN };
    }

    static updateOfferValiditySearchPageInitState(payload: ISettingsModuleState['offerValiditySearchPageInitialized']): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateSearchLockOnOfferValiditySearchPage(payload: ISettingsModuleState['offerValiditySearchPageLock']): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updateCollapsedOfferValiditySearchPage(payload: ISettingsModuleState['offerValiditySearchPageCollapsed']): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static importRecordsOnOfferValiditySearchPage(payload: Array<IDomainDetailData['id']>): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_IMPORT_RECORDS, payload };
    }

    static importInstancesOnOfferValiditySearchPage(payload: Array<string>): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_IMPORT_INSTANCES, payload };
    }

    static updateFormOnOfferValiditySearchPage(payload): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static submitOnOfferValiditySearchPage(): Action {
        return { type: SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_SUBMIT };
    }

    static dryRunOfferValidityEditPage(id: string): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateFormOfferValidityEditPage(value: any): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM, payload: value };
    }

    static updateRecordIdOfferValidityEditPage(id: string): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_RECORD_ID, payload: id };
    }

    static updateDefaultValueOnOfferValidityEditPage(data: any): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static updateValuesOnOfferValidityEditPage(data: any): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES, payload: data };
    }

    static addValuesOfferValidityEditPage(data: number): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_ADD_VALUES, payload: data };
    }

    static updateValuesFormsOnOfferValidityEditPage(data: any): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS, payload: data };
    }

    static updateValuesFormsActiveOnOfferValidityEditPage(data: Array<any>): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS_ACTIVE, payload: data };
    }

    static removeValuesFormOfferValidityEditPage(index: number): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_REMOVE_VALUES_FORM, payload: index };
    }

    static submitOnOfferValidityEditPage(): Action {
        return { type: SettingsStory.OFFER_VALIDITY_EDIT_PAGE_SUBMIT };
    }

    static dryRunAnonymizationPage(): Action {
        return { type: SettingsStory.ANONYMIZATION_EDIT_PAGE_DRY_RUN };
    }

    static updateAnonymizationPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.ANONYMIZATION_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateAutomaticAnonymizationPeriodValue(v: number): Action {
        return { type: SettingsStory.ANONYMIZATION_PAGE_UPDATE_PERIOD_VALUE, payload: v };
    }

    static submitOnAnonymizationEditPage(): Action {
        return { type: SettingsStory.ANONYMIZATION_PAGE_SUBMIT };
    }

    static dryRunSearch404Page(): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_DRY_RUN };
    }

    static updateSearch404PageInitState(v: boolean | null): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedSearch404Page(v: boolean | null): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateSearchLockOnSearch404Page(v: boolean | null): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_UPDATE_SEARCH_LOCK, payload: v };
    }

    static updatePaginationOnSearch404Page(pagination: IPaginationData | null): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_UPDATE_PAGINATION, payload: pagination };
    }

    static updateOrderOnSearch404Page(order: IOrderData | null): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_UPDATE_ORDER, payload: order };
    }

    static importRecordsOnSearch404Page(ids: Array<string>): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnSearch404Page(): Action {
        return { type: SettingsStory.SEARCH_404_PAGE_SUBMIT };
    }

    static dryRunEdit404Page(id: string): Action {
        return { type: SettingsStory.EDIT_404_PAGE_DRY_RUN, payload: id };
    }

    static updateRecordIdEdit404Page(id: string): Action {
        return { type: SettingsStory.EDIT_404_PAGE_UPDATE_RECORD_ID, payload: id };
    }

    static updateFormValueOnEdit404Page(data: any): Action {
        return { type: SettingsStory.EDIT_404_PAGE_UPDATE_FORM, payload: data };
    }

    static updateValueOnEdit404Page(data: any): Action {
        return { type: SettingsStory.EDIT_404_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static submitOnEdit404Page(): Action {
        return { type: SettingsStory.EDIT_404_PAGE_SUBMIT };
    }

    static updateEditDateFormattingPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.EDIT_DATE_FORMATTING_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static dryRunEditDateFormattingPage(): Action {
        return { type: SettingsStory.EDIT_DATE_FORMATTING_PAGE_DRY_RUN };
    }

    static updateValueOnEditDateFormattingPage(data: any): Action {
        return { type: SettingsStory.EDIT_DATE_FORMATTING_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static submitOnEditDateFormattingPage(): Action {
        return { type: SettingsStory.EDIT_DATE_FORMATTING_PAGE_SUBMIT };
    }

    static updateEditPriceFormattingPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.EDIT_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static dryRunEditPriceFormattingPage(): Action {
        return { type: SettingsStory.EDIT_PRICE_FORMATTING_PAGE_DRY_RUN };
    }

    static updateValueOnEditPriceFormattingPage(payload): Action {
        return { type: SettingsStory.EDIT_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE, payload };
    }

    static submitOnEditPriceFormattingPage(): Action {
        return { type: SettingsStory.EDIT_PRICE_FORMATTING_PAGE_SUBMIT };
    }

    static submitOnEditColorCodingRangePage(): Action {
        return { type: SettingsStory.EDIT_PRICE_COLOR_RANGE_SUBMIT };
    }

    static updateEditSystemPriceFormattingPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static dryRunEditSystemPriceFormattingPage(): Action {
        return { type: SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_DRY_RUN };
    }

    static updateValueOnEditSystemPriceFormattingPage(data: any): Action {
        return { type: SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE, payload: data };
    }

    static submitOnEditSystemPriceFormattingPage(): Action {
        return { type: SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_SUBMIT};
    }

    static dryRunOfferSendIntervalPage(): Action {
        return { type: SettingsStory.OFFER_SEND_INTERVAL_EDIT_PAGE_DRY_RUN };
    }

    static dryRunMarginColorRangePage(): Action {
        return { type: SettingsStory.MARGIN_COLOR_RANGE_EDIT_PAGE_DRY_RUN };
    }

    static updateOfferSendIntervalPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.OFFER_SEND_INTERVAL_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateMarginColorRangePageInitState(v: boolean | null): Action {
        return { type: SettingsStory.MARGIN_COLOR_RANGE_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateOfferSendIntervalValue(v: number): Action {
        return { type: SettingsStory.OFFER_SEND_INTERVAL_PAGE_UPDATE_PERIOD_VALUE, payload: v };
    }

    static updateMarginColorRangeForm(payload): Action {
        return { type: SettingsStory.MARGIN_COLOR_RANGE_PAGE_UPDATE_FORM, payload };
    }

    static submitOnOfferSendIntervalEditPage(): Action {
        return { type: SettingsStory.OFFER_SEND_INTERVAL_PAGE_SUBMIT };
    }

    static dryRunManualHandlingSearchPage(): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_DRY_RUN };
    }

    static updateManualHandlingSearchPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateCollapsedManualHandlingSearchPage(v: boolean | null): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_COLLAPSED, payload: v };
    }

    static updateInstancesOnManualHandlingSearchPage(instances: any): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_IMPORT_INSTANCES, payload: instances };
    }

    static updateFormManualHandlingSearchPage(payload): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static submitOnManualHandlingSearchPage(refresh: boolean = false): Action {
        return { type: SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_SUBMIT, payload: refresh };
    }

    static dryRunManualHandlingEditPage(id: string): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateRecordIdManualHandlingEditPage(id: string): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_RECORD_ID, payload: id };
    }

    static updateToursOnManualHandlingEditPage(data: any): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_TOURS, payload: data };
    }

    static updateDestinationsOnManualHandlingEditPage(data: any): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_DESTINATIONS, payload: data };
    }

    static updateFormOnManualHandlingEditPage(data: any): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_FORM, payload: data };
    }

    static updateOtherValuesOnManualHandlingEditPage(data: any): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_OTHER_VALUES, payload: data };
    }

    static submitOnManualHandlingEditPage(): Action {
        return { type: SettingsStory.MANUAL_HANDLING_EDIT_PAGE_SUBMIT };
    }

    static dryRunTotalPaxAmountPage(): Action {
        return { type: SettingsStory.TOTAL_PAX_AMOUNT_EDIT_PAGE_DRY_RUN };
    }

    static updateTotalPaxAmountPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateTotalPaxAmountValue(v: number): Action {
        return { type: SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_VALUE, payload: v };
    }

    static updateTotalPaxAmountOverValue(v: number): Action {
        return { type: SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_OVER_VALUE, payload: v };
    }

    static updateDaysAfterForVamoosFlightsSyncValue(v: number): Action {
        return { type: SettingsStory.DAYS_FOR_VAMOOS_FLIGHTS_SYNC_VALUE, payload: v };
    }

    static updateDaysTillForVamoosFlightsSyncValue(v: number): Action {
        return { type: SettingsStory.DAYS_TILL_VAMOOS_FLIGHTS_SYNC_VALUE, payload: v };
    }

    static updateDaysAfterForVamoosDatesSyncValue(v: number): Action {
        return { type: SettingsStory.DAYS_FOR_VAMOOS_DATES_SYNC_VALUE, payload: v };
    }

    static updateDaysTillForVamoosDatesSyncValue(v: number): Action {
        return { type: SettingsStory.DAYS_TILL_VAMOOS_DATES_SYNC_VALUE, payload: v };
    }

    static updateVamoosNotifyByMailValue(v: boolean | null): Action {
        return { type: SettingsStory.VAMOOS_NOTIFY_BY_MAIL_VALUE, payload: v };
    }

    static updateVamoosNotifyBySMSValue(v: boolean | null): Action {
        return { type: SettingsStory.VAMOOS_NOTIFY_BY_SMS_VALUE, payload: v };
    }

    static updateVamoosNotifyByAppValue(v: boolean | null): Action {
        return { type: SettingsStory.VAMOOS_NOTIFY_BY_APP_VALUE, payload: v };
    }

    static submitOnTotalPaxAmountEditPage(): Action {
        return { type: SettingsStory.TOTAL_PAX_AMOUNT_PAGE_SUBMIT };
    }

    static dryRunReturningCustomersPage(): Action {
        return { type: SettingsStory.RETURNING_CUSTOMERS_EDIT_PAGE_DRY_RUN };
    }

    static dryRunReturningVamoosPage(): Action {
        return { type: SettingsStory.RETURNING_VAMOOS_EDIT_PAGE_DRY_RUN };
    }

    static updateReturningCustomersPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.RETURNING_CUSTOMERS_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateReturningVamoosPageInitState(v: boolean | null): Action {
        return { type: SettingsStory.RETURNING_VAMOOS_PAGE_UPDATE_INIT_STATE, payload: v };
    }

    static updateReturningCustomersValue(v: boolean): Action {
        return { type: SettingsStory.RETURNING_CUSTOMERS_PAGE_UPDATE_VALUE, payload: v };
    }

    static submitOnReturningCustomersEditPage(): Action {
        return { type: SettingsStory.RETURNING_CUSTOMERS_PAGE_SUBMIT };
    }

    static submitOnVamoosSettingsEditPage(): Action {
        return { type: SettingsStory.RETURNING_VAMOOS_PAGE_SUBMIT };
    }
}
