import { OrmManager } from '@aclass/admin/managers';
import {
    ConsentStory,
    OrmStory,
    SystemStory
} from '@aclass/admin/storage/actions';
import { IEpicDi } from '@aclass/admin/storage/helpers';
import { IConsentData } from '@aclass/admin/storage/models';
import { IAdminState } from '@aclass/admin/storage/states';
import { SystemNotification } from '@aclass/core/base/system.notification';
import { DataSearchRqData } from '@aclass/core/rest/requests/data.search.rq';
import { dispatchActions, Response } from '@aclass/core/rest/response';
import { IDataSearchRs } from '@aclass/core/rest/responses/data.search.rs';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';
import { Map } from 'immutable';
import { ofType, ActionsObservable, StateObservable } from 'redux-observable';
import { concat, merge, of } from 'rxjs';
import { filter, mergeMap, switchMap } from 'rxjs/operators';

export const consentModuleEpic = (action: ActionsObservable<Action>, state: StateObservable<IAdminState>, { http }: IEpicDi) => merge(
    action.pipe(
        ofType(ConsentStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.emailPipelineModule.get('searchPageInitialized')),
        switchMap(() => {

            return http.post('consent/search', { }).pipe(
                dispatchActions((rs: Response<any>) => [
                        OrmStory.populateConsent(rs.body.results),
                        ConsentStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        ConsentStory.updateSearchLockOnSearchPage(false),
                        ConsentStory.updateFormSearchPage({ }),
                        ConsentStory.updatePaginationOnSearchPage(null),
                        ConsentStory.updateOrderOnSearchPage(null),
                        ConsentStory.updateSearchLockOnSearchPage(null),
                        ConsentStory.updateCollapsedSearchPage(null),
                        // // Unlock page
                        ConsentStory.updateSearchPageInitState(true),
                    ]
                )
            );
        })
    ),
    action.pipe(
        ofType(ConsentStory.SEARCH_PAGE_DRY_RUN),
        filter(() => !state.value.exceptionModule.get('searchPageInitialized')),
        switchMap(() => [
            // Reset prev records state
            ConsentStory.updateFormSearchPage({ }),
            ConsentStory.updateFormOnEditPage({ }),
            ConsentStory.updatePaginationOnSearchPage(null),
            ConsentStory.updateOrderOnSearchPage(null),
            ConsentStory.updateSearchLockOnSearchPage(null),
            ConsentStory.updateCollapsedSearchPage(null),
            // Unlock page
            ConsentStory.updateSearchPageInitState(true),
        ])
    ),
    action.pipe(
        ofType(ConsentStory.SEARCH_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<ISearchOptions>) => {
            const module = state.value.consentModule;
            const rq: DataSearchRqData = {
                where: module.get('searchPageForm'),
                pagination: payload.pagination ? null : module.get('searchPagePagination'),
                order: payload.order ? null : module.get('searchPageOrder'),
            };

            return concat(of(ConsentStory.updateSearchLockOnSearchPage(true)), http.post('consent/search', rq).pipe(
                dispatchActions((rs: Response<IDataSearchRs<Array<IConsentData>>>) => {

                    return [
                        OrmStory.populateConsent(rs.body.results),
                        ConsentStory.importRecordsOnSearchPage(rs.body.results.map(r => r.id)),
                        ConsentStory.updatePaginationOnSearchPage(rs.body.pagination),
                        ConsentStory.updateOrderOnSearchPage(rs.body.order),
                        ConsentStory.updateSearchLockOnSearchPage(false),
                    ];
                }
                )
            ));
        }),
    ),
    action.pipe(
        ofType(ConsentStory.CREATE_PAGE_SUBMIT),
        mergeMap(({ payload }: Action<any> | null) => {
                let data: Map<string, any>;
                let rq: any;

                data = state.value.consentModule.get('createPageForm');
                const code = data.get('code');
                const consents = [];
                data.get('texts').forEach((row) => {
                    if (row.get('text') !== null) {
                        consents.push({
                            locale: row.get('locale'),
                            text: row.get('text'),
                            code: code
                        });
                    }
                });
                rq = { consents: consents };

                return concat(of(ConsentStory.updateSearchPageInitState(true)), http.post('consent/create', rq).pipe(
                    dispatchActions((rs: Response<any>) => {
                        const count = rs.body.count;
                        const plural = count > 1 ? 'consents' : 'consent';
                        if (rs.body.success) {

                            return [
                                SystemStory.showNotification(SystemNotification.success('Success', `${count} ${plural} created successfully!`)),
                                ConsentStory.submitOnSearchPage({ }),
                                OrmStory.reloadModel(OrmManager.RELOAD_CONSENT),
                                SystemStory.navigate(['consent'], { replaceUrl: true, sharedLink: true }),
                            ];
                        } else {
                            return [
                                SystemStory.showNotification(SystemNotification.error('Error', 'Error on consent creation!')),
                            ];
                        }
                    })));
            },
        )),
    action.pipe(
        ofType(ConsentStory.EDIT_PAGE_DRY_RUN),
        mergeMap(({ payload}: Action<IConsentData['id']>) => http.get(`consent/${payload}`).pipe(
            dispatchActions((rs: Response<any>) => [
                    OrmStory.populateConsent(rs.body.results),
                    OrmStory.reloadModel(OrmManager.RELOAD_CONSENT),
                    ConsentStory.updateIdEditPage(rs.body.results[0].id),
                    ConsentStory.updateFormOnEditPage(rs.body.results),
                    ConsentStory.updateFormOnEditCodePage(rs.body.results),
                ]
            )
        ))
    ),
    action.pipe(
        ofType(ConsentStory.EDIT_PAGE_REMOVE),
        mergeMap(({ payload}: Action<IConsentData['code']>) => {
            return concat(of(ConsentStory.updateSaveLockEditPage(true)), http.delete(`consent/${payload}`).pipe(
                dispatchActions(
                    () => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Consents with code ${payload} removed`)),
                        ConsentStory.updateSearchLockOnSearchPage(false),
                        OrmStory.reloadModel(OrmManager.RELOAD_CONSENT),
                        ConsentStory.submitOnSearchPage({ }),
                        SystemStory.navigate(['consent'], { sharedLink: true })
                    ],
                    () => [
                        ConsentStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
    action.pipe(
        ofType(ConsentStory.EDIT_PAGE_SUBMIT),
        mergeMap(({ payload }: any) => {
            const id = state.value.consentModule.get('editPageRecordId');
            let form: any;
            let rq: any;
            form = state.value.consentModule.get('editPageForm');

            const code = form.getIn(['code']);
            const consents = [];
            form.get('texts').forEach((row) => {
                if (row.get('text') !== null) {
                    consents.push({
                        locale: row.get('locale'),
                        text: row.get('text'),
                        code: code
                    });
                }
            });
            rq = { consents: consents };

            return concat(of(ConsentStory.updateSaveLockEditPage(true)), http.put(`consent/${id}`, rq).pipe(
                dispatchActions(
                    (rs: Response<any>) => [
                        SystemStory.showNotification(SystemNotification.success('Success', `Consents updated: ${rs.body.updated}, consents updated: ${rs.body.created}, consents deleted: ${rs.body.deleted}`)),
                        ConsentStory.updateFormOnEditPage(rs.body.data),
                        ConsentStory.submitOnSearchPage({ }),
                        ConsentStory.updateSearchLockOnSearchPage(true),
                    ],
                    () => [
                        ConsentStory.updateSaveLockEditPage(false),
                    ]
                )
            ));
        }),
    ),
);
