import { Attr, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IConsentData {

    readonly id: number | null;

    readonly locale: string;

    readonly text: string;

    readonly code: string;

    readonly deleted: boolean;

    readonly createdAt: number | null;

    readonly updatedAt: number | null;
}

@Model({
    name: 'Consent'
})
export class Consent extends Entity<IConsentData> {

    @Attr() readonly id: number | null;

    @Attr() readonly locale: string;

    @Attr() readonly text: string | null;

    @Attr() readonly code: string | null;

    @Attr() readonly deleted: boolean | null;

    @Attr() readonly createdAt: number | null;

    @Attr() readonly updatedAt: number | null;
}
