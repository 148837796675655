import {
    aclModuleReducer,
    additionalProductModuleReducer,
    appModuleReducer,
    campaignModuleReducer,
    consentModuleReducer,
    customerModuleReducer,
    dashboardModuleReducer,
    domainDetailModuleReducer,
    emailPipelineModuleReducer,
    emailTemplateModuleReducer,
    exceptionModuleReducer,
    extraPricesModuleReducer,
    fileModuleReducer,
    generalRemarkModuleReducer,
    internationalizationModuleReducer,
    leadScoringModuleReducer,
    notificationModuleReducer,
    offlineBookingEmailModuleReducer,
    opportunityModuleReducer,
    ormReducer,
    overviewModuleReducer,
    packageItineraryModuleReducer,
    pipelineModuleReducer,
    questionnaireModuleReducer,
    quoteModuleReducer,
    quoteTemplateModuleReducer,
    redirectModuleReducer,
    rootReducer,
    settingsModuleReducer,
    signatureModuleReducer,
    smsTemplateModuleReducer,
    soldoutProductModuleReducer,
    splitTestModuleReducer,
    systemModuleReducer,
    templateModuleReducer,
    userModuleReducer,
} from '@aclass/admin/storage/reducers';
import { combineReducers, AnyAction, Reducer } from 'redux';
import { combineEpics } from 'redux-observable';
import {
    aclModuleEpic,
    additionalProductModuleEpic,
    appModuleEpic,
    campaignModuleEpic,
    consentModuleEpic,
    customerModuleEpic,
    dashboardModuleEpic,
    domainDetailModuleEpic,
    emailPipelineModuleEpic,
    emailTemplateModuleEpic,
    exceptionModuleEpic,
    extraPricesModuleEpic,
    fileModuleEpic,
    generalRemarkModuleEpic,
    internationalizationModuleEpic,
    leadScoringModuleEpic,
    notificationModuleEpic,
    offlineBookingEmailModuleEpic,
    opportunityModuleEpic,
    ormModuleEpic,
    overviewModuleEpic,
    packageItineraryModuleEpic,
    pipelineModuleEpic,
    questionnaireModuleEpic,
    quoteModuleEpic,
    quoteTemplateModuleEpic,
    redirectModuleEpic,
    rootModuleEpic,
    settingsModuleEpic,
    signatureModuleEpic,
    smsTemplateModuleEpic,
    soldoutProductModuleEpic,
    splitTestModuleEpic,
    systemModuleEpic,
    templateModuleEpic,
    userModuleEpic,
} from './epics';

export const baseEpic: any = combineEpics(
    aclModuleEpic,
    appModuleEpic,
    campaignModuleEpic,
    consentModuleEpic,
    customerModuleEpic,
    dashboardModuleEpic,
    domainDetailModuleEpic,
    emailPipelineModuleEpic,
    emailTemplateModuleEpic,
    exceptionModuleEpic,
    extraPricesModuleEpic,
    fileModuleEpic,
    generalRemarkModuleEpic,
    internationalizationModuleEpic,
    leadScoringModuleEpic,
    quoteTemplateModuleEpic,
    notificationModuleEpic,
    offlineBookingEmailModuleEpic,
    opportunityModuleEpic,
    ormModuleEpic,
    overviewModuleEpic,
    packageItineraryModuleEpic,
    pipelineModuleEpic,
    additionalProductModuleEpic,
    quoteModuleEpic,
    questionnaireModuleEpic,
    redirectModuleEpic,
    rootModuleEpic,
    signatureModuleEpic,
    smsTemplateModuleEpic,
    soldoutProductModuleEpic,
    splitTestModuleEpic,
    systemModuleEpic,
    templateModuleEpic,
    userModuleEpic,
    settingsModuleEpic,
);

const composeReducers = <State>(...reducers: Reducer<State>[]): Reducer<State> => (s: any, action: AnyAction) => reducers.reduce((st, reducer) => reducer(st, action), s);

export const baseReducer: any = composeReducers(
    rootReducer(),
    combineReducers({
        aclModule: aclModuleReducer,
        additionalProductModule: additionalProductModuleReducer,
        appModule: appModuleReducer,
        campaignModule: campaignModuleReducer,
        consentModule: consentModuleReducer,
        customerModule: customerModuleReducer,
        dashboardModule: dashboardModuleReducer,
        domainDetailModule: domainDetailModuleReducer,
        emailPipelineModule: emailPipelineModuleReducer,
        emailTemplateModule: emailTemplateModuleReducer,
        exceptionModule: exceptionModuleReducer,
        extraPricesModule: extraPricesModuleReducer,
        fileModule: fileModuleReducer,
        generalRemarkModule: generalRemarkModuleReducer,
        internationalizationModule: internationalizationModuleReducer,
        leadScoringModule: leadScoringModuleReducer,
        notificationModule: notificationModuleReducer,
        offlineBookingEmailModule: offlineBookingEmailModuleReducer,
        opportunityModule: opportunityModuleReducer,
        orm: ormReducer,
        overviewModule: overviewModuleReducer,
        packageItineraryModule: packageItineraryModuleReducer,
        pipelineModule: pipelineModuleReducer,
        questionnaireModule: questionnaireModuleReducer,
        quoteModule: quoteModuleReducer,
        quoteTemplateModule: quoteTemplateModuleReducer,
        redirectModule: redirectModuleReducer,
        settingsModule: settingsModuleReducer,
        signatureModule: signatureModuleReducer,
        smsTemplateModule: smsTemplateModuleReducer,
        soldoutModule: soldoutProductModuleReducer,
        splitTestModule: splitTestModuleReducer,
        systemModule: systemModuleReducer,
        templateModule: templateModuleReducer,
        userModule: userModuleReducer,
    }),
);
