import { IConsentData, ITWOptionData } from '@aclass/admin/storage/models';
import { IConsentModuleState } from '@aclass/admin/storage/states';
import { Story, Task } from '@aclass/core/decorators/storage.decorator';
import { IOrderData } from '@aclass/core/rest/order';
import { IPaginationData } from '@aclass/core/rest/pagination';
import { ISearchOptions } from '@aclass/core/rest/search.options';
import { Action } from '@aclass/core/storage/action';

@Story({
    name: 'consent'
})
export class ConsentStory {

    @Task() static PRODUCT_UPDATE_OPTIONS: string;

    @Task() static PRODUCT_ID_UPDATE_OPTIONS_IDS: string;

    @Task() static PRODUCT_OPTION_SEARCH: string;

    @Task() static IMPORT_HOTELS_RECORDS: string;

    @Task() static SEARCH_PAGE_DRY_RUN: string;

    @Task() static SEARCH_PAGE_UPDATE_INIT_STATE: string;

    @Task() static SEARCH_PAGE_UPDATE_COLLAPSED: string;

    @Task() static SEARCH_PAGE_UPDATE_FORM: string;

    @Task() static SEARCH_PAGE_UPDATE_SEARCH_LOCK: string;

    @Task() static SEARCH_PAGE_UPDATE_PAGINATION: string;

    @Task() static SEARCH_PAGE_UPDATE_ORDER: string;

    @Task() static SEARCH_PAGE_IMPORT_RECORDS: string;

    @Task() static SEARCH_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_DRY_RUN: string;

    @Task() static CREATE_PAGE_SUBMIT: string;

    @Task() static CREATE_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_DRY_RUN: string;

    @Task() static EDIT_PAGE_UPDATE_PRODUCT_ID: string;

    @Task() static EDIT_PAGE_UPDATE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_CODE_FORM: string;

    @Task() static EDIT_PAGE_UPDATE_SAVE_LOCK: string;

    @Task() static EDIT_PAGE_REMOVE: string;

    @Task() static EDIT_PAGE_SUBMIT: string;

    @Task() static EDIT_PAGE_UPDATE_ID: string;

    static productUpdateOptions( payload: Array<ITWOptionData>): Action {
        return { type: ConsentStory.PRODUCT_UPDATE_OPTIONS, payload  };
    }

    static productIdUpdateOptions(uid: number, ids: Array<ITWOptionData['id']>): Action {
        return { type: ConsentStory.PRODUCT_ID_UPDATE_OPTIONS_IDS, payload: { uid, ids } };
    }

    static productSearchOptions(payload): Action {
        return { type: ConsentStory.PRODUCT_OPTION_SEARCH, payload };
    }

    static importProductsIds(items: Array<Object>): Action {
        return { type: ConsentStory.IMPORT_HOTELS_RECORDS, payload: items };
    }

    static dryRunSearchPage(): Action {
        return { type: ConsentStory.SEARCH_PAGE_DRY_RUN };
    }

    static createPageDryRun(payload): Action {
        return { type: ConsentStory.CREATE_PAGE_DRY_RUN, payload };
    }

    static updateSearchPageInitState(payload: IConsentModuleState['searchPageInitialized']): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_INIT_STATE, payload };
    }

    static updateCollapsedSearchPage(payload: IConsentModuleState['searchPageCollapsed']): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_COLLAPSED, payload };
    }

    static updateFormSearchPage(payload): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_FORM, payload };
    }

    static updateSearchLockOnSearchPage(payload: IConsentModuleState['searchPageLock']): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_SEARCH_LOCK, payload };
    }

    static updatePaginationOnSearchPage(payload: IPaginationData | null): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_PAGINATION, payload };
    }

    static updateOrderOnSearchPage(payload: IOrderData | null): Action {
        return { type: ConsentStory.SEARCH_PAGE_UPDATE_ORDER, payload };
    }

    static importRecordsOnSearchPage(ids: Array<number>): Action {
        return { type: ConsentStory.SEARCH_PAGE_IMPORT_RECORDS, payload: ids };
    }

    static submitOnSearchPage(payload: ISearchOptions = { }): Action {
        return { type: ConsentStory.SEARCH_PAGE_SUBMIT, payload };
    }

    static updateSaveLockCreatePage(payload: IConsentModuleState['createPageSaveLock']): Action {
        return { type: ConsentStory.CREATE_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static submitOnCreatePage(): Action {
        return { type: ConsentStory.CREATE_PAGE_SUBMIT };
    }

    static dryRunEditPage(id: IConsentData['id']): Action {
        return { type: ConsentStory.EDIT_PAGE_DRY_RUN, payload: id };
    }

    static updateProductIdEditPage(payload: IConsentModuleState['editPageRecordId']): Action {
        return { type: ConsentStory.EDIT_PAGE_UPDATE_PRODUCT_ID, payload };
    }

    static updateFormOnEditPage(payload): Action {
        return { type: ConsentStory.EDIT_PAGE_UPDATE_FORM, payload };
    }

    static updateFormOnEditCodePage(payload): Action {
        return { type: ConsentStory.EDIT_PAGE_UPDATE_CODE_FORM, payload };
    }

    static updateSaveLockEditPage(payload: IConsentModuleState['editPageSaveLock']): Action {
        return { type: ConsentStory.EDIT_PAGE_UPDATE_SAVE_LOCK, payload };
    }

    static removeOnSearchPage(code: IConsentData['code']): Action {
        return { type: ConsentStory.EDIT_PAGE_REMOVE, payload: code};
    }

    static submitOnEditPage(): Action {
        return { type: ConsentStory.EDIT_PAGE_SUBMIT };
    }

    static updateIdEditPage(id: IConsentData['id']): Action {
        return { type: ConsentStory.EDIT_PAGE_UPDATE_ID, payload: id };
    }
}
