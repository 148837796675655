import { Consent } from '@aclass/admin/storage/models/consent';
import { Opportunity } from '@aclass/admin/storage/models/opportunity';
import { Fk, Model } from '@aclass/core/decorators/orm.decorator';
import { Model as Entity } from 'redux-orm';

export interface IConsentOpportunityMappingData {

    readonly consentId: Consent | number | null;

    readonly enquireDataId: Opportunity | number | null;
}

@Model({
    name: 'ConsentOpportunityMapping',
    idAttribute: 'consentId'
})
export class ConsentOpportunityMapping extends Entity<IConsentOpportunityMappingData> {

    @Fk({ to: 'Consent', relatedName: 'relatedConsentOpportunityMappings' }) readonly consentId: Consent | null;

    @Fk({ to: 'Opportunity', relatedName: 'relatedConsentOpportunityMappings' }) readonly enquireDataId: Opportunity | null;

}
