import { OrmStory } from '@aclass/admin/storage/actions';
import {
    IAdditionalProductData,
    IConsentData,
    IDocumentData,
    IExtraPriceData,
    IGeneralRemarkData,
    IImageData,
    IOpportunityEmailMessageLogData,
    IOverviewData,
    IQuestionData,
    IRedirectData,
    IRoleData,
    ISettingData,
    ISignatureData,
} from '@aclass/admin/storage/models';
import { INITIAL_ORM_STATE } from '@aclass/admin/storage/states';
import { Action } from '@aclass/core/storage/action';
import { ICampaignData } from '@aclass/core/storage/models/campaign';
import { ICustomerData } from '@aclass/core/storage/models/customer';
import { IQuoteData } from '@aclass/core/storage/models/quote';
import { ORMCommonState } from 'redux-orm';
import { orm } from '../orm';

export const ormReducer = (state: ORMCommonState = INITIAL_ORM_STATE, action: Action) => {
    const s = orm.session(state);

    const {
        AdditionalProduct,
        Campaign,
        CmsAirport,
        CmsDestination,
        CmsHotel,
        CmsSmalltour,
        CmsTour,
        Combination,
        CombinationRevision,
        CombinationToTourRevisionMapping,
        Consent,
        ConsentOpportunityMapping,
        Country,
        Customer,
        CustomerEmailMessageLog,
        CustomerLog,
        Delivery,
        Vamoos,
        Destination,
        DestinationRevision,
        DestinationToTourRevisionMapping,
        Document,
        TravelWiseDocument,
        DocumentCustomerMapping,
        DocumentEmailTemplateMapping,
        DocumentOfflineBookingMapping,
        DocumentOpportunityMapping,
        DocumentTravelWiseOpportunityMapping,
        DomainDetail,
        EmailMessage,
        EmailPipeline,
        EmailTemplate,
        Exception,
        ExtraPrice,
        GeneralRemark,
        Image,
        Itinerary,
        ItineraryDay,
        ItineraryPrice,
        ItineraryRoom,
        ItineraryRoomToPassengerMapping,
        Notification,
        OfferValidity,
        OfficeWorkingHours,
        OfflineBooking,
        OfflineBookingEmail,
        OfflineBookingPassenger,
        OfflineBookingPayment,
        Opportunity,
        OpportunityEmailMessageLog,
        OpportunityLog,
        Overview,
        OverviewCondition,
        OverviewDefinition,
        OverviewDisplay,
        OverviewReport,
        Page404,
        Permission,
        PipelineLevel,
        PCCombination,
        PCTour,
        PCTourPackage,
        Question,
        Questionnaire,
        QuestionnaireResult,
        Quote,
        QuoteGallery,
        QuotePreview,
        QuoteTemplate,
        QuoteWorkingHours,
        Redirect,
        Role,
        Setting,
        Signature,
        SmsMessage,
        SmsTemplate,
        SoldoutProduct,
        SourceMessage,
        SplitTest,
        Tour,
        TourDestinations,
        TourRevision,
        TourSettings,
        TranslationMessage,
        TWContract,
        TWOption,
        TWProduct,
        User
    }: any = s;
    const { type, payload } = action;

    switch (type) {
        case OrmStory.DROP_ADDITIONAL_PRODUCTS:
            (<Array<IAdditionalProductData['id']>>payload).forEach(record => {
                const r = AdditionalProduct.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_DOCUMENTS:
            (<Array<IDocumentData['id']>>payload).forEach(record => {
                const r = Document.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_LOGS:
            (<Array<IOpportunityEmailMessageLogData['id']>>payload).forEach(record => {
                const r = OpportunityEmailMessageLog.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_GENERAL_REMARKS:
            (<Array<IGeneralRemarkData['id']>>payload).forEach(record => {
                const r = GeneralRemark.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_IMAGES:
            (<Array<IImageData['id']>>payload).forEach(record => {
                const r = Image.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_REDIRECTS:
            (<Array<IRedirectData['id']>>payload).forEach(record => {
                const r = Redirect.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_ROLES:
            (<Array<IRoleData['name']>>payload).forEach(record => {
                const r = Role.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_CONSENTS:
            (<Array<IConsentData['id']>>payload).forEach(record => {
                const r = Consent.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_SETTINGS:
            (<Array<ISettingData<any>['id']>>payload).forEach(record => {
                const r = Setting.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_SIGNATURES:
            (<Array<ISignatureData['id']>>payload).forEach(record => {
                const r = Signature.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_CAMPAIGNS:
            (<Array<ICampaignData['id']>>payload).forEach(record => {
                const r = Campaign.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_CUSTOMERS:
            (<Array<ICustomerData['id']>>payload).forEach(record => {
                const r = Customer.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_EXTRA_PRICE:
            (<Array<IExtraPriceData['id']>>payload).forEach(record => {
                const r = ExtraPrice.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_OVERVIEWS:
            (<Array<IOverviewData['id']>>payload).forEach(record => {
                const r = Overview.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_QUESTIONS:
            (<Array<IQuestionData['id']>>payload).forEach(record => {
                const r = Question.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.DROP_QUOTES:
            (<Array<IQuoteData['id']>>payload).forEach(record => {
                const r = Quote.withId(record);
                if (!r) {
                    return;
                }
                r.delete();
            });
            break;
        case OrmStory.POPULATE_404_PAGE:
            payload.forEach(record => Page404.upsert(record));
            break;
        case OrmStory.POPULATE_CAMPAIGNS:
            payload.forEach(record => Campaign.upsert(record));
            break;
        case OrmStory.POPULATE_CMS_AIRPORTS:
            payload.forEach(record => CmsAirport.upsert(record));
            break;
        case OrmStory.POPULATE_CMS_DESTINATIONS:
            payload.forEach(record => CmsDestination.upsert(record));
            break;
        case OrmStory.POPULATE_CMS_HOTELS:
            payload.forEach(record => CmsHotel.upsert(record));
            break;
        case OrmStory.POPULATE_CMS_SMALLTOURS:
            payload.forEach(record => CmsSmalltour.upsert(record));
            break;
        case OrmStory.POPULATE_CMS_TOURS:
            payload.forEach(record => CmsTour.upsert(record));
            break;
        case OrmStory.POPULATE_COMBINATIONS:
            payload.forEach(record => Combination.upsert(record));
            break;
        case OrmStory.POPULATE_COMBINATION_REVISIONS:
            payload.forEach(record => CombinationRevision.upsert(record));
            break;
        case OrmStory.POPULATE_COMBINATION_TO_TOUR_REVISION_MAPPINGS:
            payload.forEach(record => CombinationToTourRevisionMapping.upsert(record));
            break;
        case OrmStory.POPULATE_CONSENT:
            payload.forEach(record => Consent.upsert(record));
            break;
        case OrmStory.POPULATE_COUNTRIES:
            payload.forEach(record => Country.upsert(record));
            break;
        case OrmStory.POPULATE_CUSTOMERS:
            payload.forEach(record => Customer.upsert(record));
            break;
        case OrmStory.POPULATE_CUSTOMER_EMAIL_MESSAGE_LOGS:
            payload.forEach(record => CustomerEmailMessageLog.upsert(record));
            break;
        case OrmStory.POPULATE_CUSTOMER_LOGS:
            payload.forEach(record => CustomerLog.upsert(record));
            break;
        case OrmStory.POPULATE_DELIVERIES:
            payload.forEach(record => Delivery.upsert(record));
            break;
        case OrmStory.POPULATE_VAMOOS:
            payload.forEach(record => Vamoos.upsert(record));
            break;
        case OrmStory.POPULATE_DESTINATIONS:
            payload.forEach(record => Destination.upsert(record));
            break;
        case OrmStory.POPULATE_DESTINATION_REVISIONS:
            payload.forEach(record => DestinationRevision.upsert(record));
            break;
        case OrmStory.POPULATE_DESTINATION_TO_TOUR_REVISION_MAPPINGS:
            payload.forEach(record => DestinationToTourRevisionMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENTS:
            payload.forEach(record => Document.upsert(record));
            break;
        case OrmStory.POPULATE_TRAVEL_WISE_DOCUMENTS:
            payload.forEach(record => TravelWiseDocument.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENT_CUSTOMER_MAPPINGS:
            payload.forEach(record => DocumentCustomerMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS:
            payload.forEach(record => DocumentEmailTemplateMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENT_OFFLINE_BOOKING_MAPPINGS:
            payload.forEach(record => DocumentOfflineBookingMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENT_OPPORTUNITY_MAPPINGS:
            payload.forEach(record => DocumentOpportunityMapping.upsert(record));
            break;
        case OrmStory.POPULATE_CONSENT_OPPORTUNITY_MAPPINGS:
            payload.forEach(record => ConsentOpportunityMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOCUMENT_TRAVEL_WISE_OPPORTUNITY_MAPPINGS:
            payload.forEach(record => DocumentTravelWiseOpportunityMapping.upsert(record));
            break;
        case OrmStory.POPULATE_DOMAIN_DETAILS:
            payload.forEach(record => DomainDetail.upsert(record));
            break;
        case OrmStory.POPULATE_EMAIL_MESSAGES:
            payload.forEach(record => EmailMessage.upsert(record));
            break;
        case OrmStory.POPULATE_EMAIL_PIPELINE:
            payload.forEach(record => EmailPipeline.upsert(record));
            break;
        case OrmStory.POPULATE_EMAIL_TEMPLATES:
            payload.forEach(record => EmailTemplate.upsert(record));
            break;
        case OrmStory.POPULATE_SMS_MESSAGES:
            payload.forEach(record => SmsMessage.upsert(record));
            break;
        case OrmStory.POPULATE_SMS_TEMPLATES:
            payload.forEach(record => SmsTemplate.upsert(record));
            break;
        case OrmStory.POPULATE_EXCEPTIONS:
            payload.forEach(record => Exception.upsert(record));
            break;
        case OrmStory.POPULATE_EXTRA_PRICES:
            payload.forEach(record => ExtraPrice.upsert(record));
            break;
        case OrmStory.POPULATE_GENERAL_REMARK:
            payload.forEach(record => GeneralRemark.upsert(record));
            break;
        case OrmStory.POPULATE_IMAGES:
            payload.forEach(record => Image.upsert(record));
            break;
        case OrmStory.POPULATE_NOTIFICATION:
            payload.forEach(record => Notification.upsert(record));
            break;
        case OrmStory.POPULATE_OFFER_VALIDITY:
            payload.forEach(record => OfferValidity.upsert(record));
            break;
        case OrmStory.POPULATE_OFFLINE_BOOKINGS:
            payload.forEach(record => OfflineBooking.upsert(record));
            break;
        case OrmStory.POPULATE_OFFLINE_BOOKING_EMAIL:
            payload.forEach(record => OfflineBookingEmail.upsert(record));
            break;
        case OrmStory.POPULATE_OFFLINE_BOOKING_PASSENGERS:
            payload.forEach(record => OfflineBookingPassenger.upsert(record));
            break;
        case OrmStory.POPULATE_OFFLINE_BOOKING_PAYMENTS:
            payload.forEach(record => OfflineBookingPayment.upsert(record));
            break;
        case OrmStory.POPULATE_OPPORTUNITIES:
            payload.forEach(record => Opportunity.upsert(record));
            break;
        case OrmStory.POPULATE_OPPORTUNITY_EMAIL_MESSAGE_LOGS:
            payload.forEach(record => OpportunityEmailMessageLog.upsert(record));
            break;
        case OrmStory.POPULATE_OPPORTUNITY_LOGS:
            payload.forEach(record => OpportunityLog.upsert(record));
            break;
        case OrmStory.POPULATE_OVERVIEWS:
            payload.forEach(record => Overview.upsert(record));
            break;
        case OrmStory.POPULATE_OVERVIEW_CONDITIONS:
            payload.forEach(record => OverviewCondition.upsert(record));
            break;
        case OrmStory.POPULATE_OVERVIEW_DEFINITIONS:
            payload.forEach(record => OverviewDefinition.upsert(record));
            break;
        case OrmStory.POPULATE_OVERVIEW_DISPLAYS:
            payload.forEach(record => OverviewDisplay.upsert(record));
            break;
        case OrmStory.POPULATE_OVERVIEW_REPORTS:
            payload.forEach(record => OverviewReport.upsert(record));
            break;
        case OrmStory.POPULATE_PERMISSIONS:
            payload.forEach(record => Permission.upsert(record));
            break;
        case OrmStory.POPULATE_PIPELINE_LEVELS:
            payload.forEach(record => PipelineLevel.upsert(record));
            break;
        case OrmStory.POPULATE_ADDITIONAL_PRODUCTS:
            payload.forEach(record => AdditionalProduct.upsert(record));
            break;
        case OrmStory.POPULATE_QUESTIONNAIRES:
            payload.forEach(record => Questionnaire.upsert(record));
            break;
        case OrmStory.POPULATE_QUESTIONNAIRE_RESULTS:
            payload.forEach(record => QuestionnaireResult.upsert(record));
            break;
        case OrmStory.POPULATE_QUESTIONS:
            payload.forEach(record => Question.upsert(record));
            break;
        case OrmStory.POPULATE_QUOTES:
            payload.forEach(record => Quote.upsert(record));
            break;
        case OrmStory.POPULATE_QUOTE_GALLERIES:
            payload.forEach(record => QuoteGallery.upsert(record));
            break;
        case OrmStory.POPULATE_QUOTE_PREVIEWS:
            payload.forEach(record => QuotePreview.upsert(record));
            break;
        case OrmStory.POPULATE_QUOTE_TEMPLATES:
            payload.forEach(record => QuoteTemplate.upsert(record));
            break;
        case OrmStory.POPULATE_PC_TOUR_PACKAGES:
            payload.forEach(record => PCTourPackage.upsert(record));
            break;
        case OrmStory.POPULATE_ITINERARIES:
            payload.forEach(record => Itinerary.upsert(record));
            break;
        case OrmStory.POPULATE_ITINERARY_DAYS:
            payload.forEach(record => ItineraryDay.upsert(record));
            break;
        case OrmStory.POPULATE_ITINERARY_ROOMS:
            payload.forEach(record => ItineraryRoom.upsert(record));
            break;
        case OrmStory.POPULATE_ITINERARY_ROOM_TO_PASSENGER_MAPPINGS:
            payload.forEach(record => ItineraryRoomToPassengerMapping.upsert(record));
            break;
        case OrmStory.POPULATE_ITINERARY_PRICES:
            payload.forEach(record => ItineraryPrice.upsert(record));
            break;
        case OrmStory.POPULATE_PC_TOURS:
            payload.forEach(record => PCTour.upsert(record));
            break;
        case OrmStory.POPULATE_PC_COMBINATIONS:
            payload.forEach(record => PCCombination.upsert(record));
            break;
        case OrmStory.POPULATE_TW_PRODUCTS:
            payload.forEach(record => TWProduct.upsert(record));
            break;
        case OrmStory.POPULATE_TW_CONTRACTS:
            payload.forEach(record => TWContract.upsert(record));
            break;
        case OrmStory.POPULATE_TW_OPTIONS:
            payload.forEach(record => TWOption.upsert(record));
            break;
        case OrmStory.POPULATE_REDIRECTS:
            payload.forEach(record => Redirect.upsert(record));
            break;
        case OrmStory.POPULATE_ROLES:
            payload.forEach(record => Role.upsert(record));
            break;
        case OrmStory.POPULATE_SOLDOUS:
            payload.forEach(record => SoldoutProduct.upsert(record));
            break;
        case OrmStory.POPULATE_SETTINGS:
            payload.forEach(record => Setting.upsert(record));
            break;
        case OrmStory.POPULATE_SIGNATURES:
            payload.forEach(record => Signature.upsert(record));
            break;
        case OrmStory.POPULATE_SOURCE_MESSAGES:
            payload.forEach(record => SourceMessage.upsert(record));
            break;
        case OrmStory.POPULATE_SPLIT_TESTS:
            payload.forEach(record => SplitTest.upsert(record));
            break;
        case OrmStory.POPULATE_TOURS_DESTINATIONS:
            payload.forEach(record => TourDestinations.upsert(record));
            break;
        case OrmStory.POPULATE_TOURS_SETTINGS:
            payload.forEach(record => TourSettings.upsert(record));
            break;
        case OrmStory.POPULATE_TOURS:
            payload.forEach(record => Tour.upsert(record));
            break;
        case OrmStory.POPULATE_TOUR_REVISIONS:
            payload.forEach(record => TourRevision.upsert(record));
            break;
        case OrmStory.POPULATE_TRANSLATED_MESSAGES:
            payload.forEach(record => TranslationMessage.upsert(record));
            break;
        case OrmStory.POPULATE_USERS:
            payload.forEach(record => User.upsert(record));
            break;
        case OrmStory.POPULATE_QUOTE_WORKING_HOURS:
            payload.forEach(record => QuoteWorkingHours.upsert(record));
            break;
        case OrmStory.POPULATE_OFFICE_WORKING_HOURS:
            payload.forEach(record => OfficeWorkingHours.upsert(record));
            break;
        case OrmStory.UPDATE_DELIVERIES:
            payload.forEach(record => {
                const ref = Delivery.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                Delivery.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_VAMOOS:
            payload.forEach(record => {
                const ref = Vamoos.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                Vamoos.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_CUSTOMERS:
            payload.forEach(record => {
                const ref = Customer.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                Customer.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_OPPORTUNITIES:
            payload.forEach(record => {
                const ref = Opportunity.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                Opportunity.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_ADDITIONAL_PRODUCTS:
            payload.forEach(record => {
                const ref = AdditionalProduct.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                AdditionalProduct.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_CAMPAIGNS:
            payload.forEach(record => {
                const ref = Campaign.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                Campaign.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.UPDATE_USERS:
            payload.forEach(record => {
                const ref = User.withId(record.id).ref;
                delete record.id;
                if (!ref) {
                    return;
                }
                User.upsert({ ...ref, ...record });
            });
            break;
        case OrmStory.TRUNCATE_DOCUMENT_EMAIL_TEMPLATE_MAPPINGS:
            DocumentEmailTemplateMapping.all().toModelArray().forEach(v => v.delete());
            break;
    }

    return s.state;
};
