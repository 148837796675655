import { SettingsStory } from '@aclass/admin/storage/actions';
import { INITIAL_SETTINGS_STATE, ISettingsModuleState } from '@aclass/admin/storage/states/settings.module.state';
import { Action } from '@aclass/core/storage/action';
import { TypedState } from '@aclass/core/storage/states/module.state';
import { fromJS, List, Map } from 'immutable';

export const settingsModuleReducer = (state: TypedState<ISettingsModuleState> = INITIAL_SETTINGS_STATE, action: Action) => {
    const { type, payload } = action;
    switch (type) {
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('quoteWorkingHoursSearchPageInitialized', () => payload);
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('quoteWorkingHoursSearchPageCollapsed', () => payload);
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('quoteWorkingHoursSearchPageLock', () => payload);
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('quoteWorkingHoursSearchPageResults', () => List(payload));
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('quoteWorkingHoursSearchPageInstances', () => List(payload));
        case SettingsStory.QUOTE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM:
            return state.update('quoteWorkingHoursSearchPageForm', () => fromJS(payload));
        case SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('quoteWorkingHoursEditPageRecordId', () => payload);
        case SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('quoteWorkingHoursSaveLock', () => payload);
        case SettingsStory.QUOTE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM:
            return state.update('officeWorkingHoursEditPageForm', () => fromJS(payload));
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('officeWorkingHoursSearchPageInitialized', () => payload);
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('officeWorkingHoursSearchPageCollapsed', () => payload);
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('officeWorkingHoursSearchPageLock', () => payload);
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('officeWorkingHoursSearchPageResults', () => List(payload));
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('officeWorkingHoursSearchPageInstances', () => List(payload));
        case SettingsStory.OFFICE_WORKING_HOURS_SEARCH_PAGE_UPDATE_FORM:
            return state.update('officeWorkingHoursSearchPageForm', () => fromJS(payload));
        case SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('officeWorkingHoursEditPageRecordId', () => payload);
        case SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_SAVE_LOCK:
            return state.update('officeWorkingHoursSaveLock', () => payload);
        case SettingsStory.OFFICE_WORKING_HOURS_EDIT_PAGE_UPDATE_FORM:
            return state.update('officeWorkingHoursEditPageForm', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('offerValiditySearchPageInitialized', () => payload);
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('offerValiditySearchPageCollapsed', () => payload);
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('offerValiditySearchPageLock', () => payload);
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_IMPORT_RECORDS:
            return state.update('offerValiditySearchPageResults', () => List(payload));
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('offerValiditySearchPageInstances', () => List(payload));
        case SettingsStory.OFFER_VALIDITY_SEARCH_PAGE_UPDATE_FORM:
            return state.update('offerValiditySearchPageForm', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('offerValidityEditPageRecordId', () => payload);
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES:
            return state.update('offerValidityEditPageValues', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM:
            return state.update('offerValidityEditPageForm', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_FORM_VALUE:
            return state.update('offerValidityEditPageDefaultValue', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS:
            return state.update('offerValidityEditPageValuesForms', () => fromJS(payload));
        case SettingsStory.OFFER_VALIDITY_EDIT_PAGE_UPDATE_VALUES_FORMS_ACTIVE:
            return state.update('offerValidityEditPageValuesFormsActive', () => fromJS(payload));
        case SettingsStory.ANONYMIZATION_PAGE_UPDATE_INIT_STATE:
            return state.update('anonymizationPageInitialized', () => payload);
        case SettingsStory.ANONYMIZATION_PAGE_UPDATE_PERIOD_VALUE:
            return state.update('anonymizationEditPageValue', () => payload);
        case SettingsStory.SEARCH_404_PAGE_UPDATE_INIT_STATE:
            return state.update('search404PageInitialized', () => payload);
        case SettingsStory.SEARCH_404_PAGE_UPDATE_COLLAPSED:
            return state.update('search404PageCollapsed', () => payload);
        case SettingsStory.SEARCH_404_PAGE_UPDATE_SEARCH_LOCK:
            return state.update('search404PageLock', () => payload);
        case SettingsStory.SEARCH_404_PAGE_UPDATE_PAGINATION:
            return state.update('search404PagePagination', () => Map(payload));
        case SettingsStory.SEARCH_404_PAGE_UPDATE_ORDER:
            return state.update('search404PageOrder', () => Map(payload));
        case SettingsStory.SEARCH_404_PAGE_IMPORT_RECORDS:
            return state.update('search404PageResults', () => List(payload));
        case SettingsStory.EDIT_404_PAGE_UPDATE_RECORD_ID:
            return state.update('edit404PageRecordId', () => payload);
        case SettingsStory.EDIT_404_PAGE_UPDATE_FORM_VALUE:
            return state.update('edit404PageFormValue', () => fromJS(payload));
        case SettingsStory.EDIT_404_PAGE_UPDATE_FORM:
            return state.update('edit404PageForm', () => fromJS(payload));
        case SettingsStory.EDIT_DATE_FORMATTING_PAGE_UPDATE_INIT_STATE:
            return state.update('editDateFormattingPageInitialized', () => payload);
        case SettingsStory.EDIT_DATE_FORMATTING_PAGE_UPDATE_FORM_VALUE:
            return state.update('editDateFormattingPageFormValue', () => fromJS(payload));
        case SettingsStory.EDIT_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE:
            return state.update('editPriceFormattingPageInitialized', () => payload);
        case SettingsStory.EDIT_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE:
            return state.update('editPriceFormattingPageFormValue', () => fromJS(payload));
        case SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_INIT_STATE:
            return state.update('editSystemPriceFormattingPageInitialized', () => payload);
        case SettingsStory.EDIT_SYSTEM_PRICE_FORMATTING_PAGE_UPDATE_FORM_VALUE:
            return state.update('editSystemPriceFormattingPageFormValue', () => fromJS(payload));
        case SettingsStory.OFFER_SEND_INTERVAL_PAGE_UPDATE_INIT_STATE:
            return state.update('offerSendIntervalPageInitialized', () => payload);
        case SettingsStory.MARGIN_COLOR_RANGE_PAGE_UPDATE_INIT_STATE:
            return state.update('marginColorRangePageInitialized', () => payload);
        case SettingsStory.OFFER_SEND_INTERVAL_PAGE_UPDATE_PERIOD_VALUE:
            return state.update('offerSendIntervalEditPageValue', () => payload);
        case SettingsStory.MARGIN_COLOR_RANGE_PAGE_UPDATE_FORM:
            return state.update('marginColorRangeEditPageForm', () => payload);
        case SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_INIT_STATE:
            return state.update('manualHandlingSearchPageInitialized', () => payload);
        case SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_COLLAPSED:
            return state.update('manualHandlingSearchPageCollapsed', () => payload);
        case SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_IMPORT_INSTANCES:
            return state.update('manualHandlingSearchPageInstances', () => List(payload));
        case SettingsStory.MANUAL_HANDLING_SEARCH_PAGE_UPDATE_FORM:
            return state.update('manualHandlingSearchPageForm', () => fromJS(payload));
        case SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_FORM:
            return state.update('manualHandlingEditPageForm', () => fromJS(payload));
        case SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_RECORD_ID:
            return state.update('manualHandlingEditPageRecordId', () => payload);
        case SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_TOURS:
            return state.update('manualHandlingEditPageTours', () => fromJS(payload));
        case SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_DESTINATIONS:
            return state.update('manualHandlingEditPageDestinations', () => fromJS(payload));
        case SettingsStory.MANUAL_HANDLING_EDIT_PAGE_UPDATE_OTHER_VALUES:
            return state.update('manualHandlingEditPageOtherValues', () => fromJS(payload));
        case SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_INIT_STATE:
            return state.update('totalPaxAmountPageInitialized', () => payload);
        case SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_VALUE:
            return state.update('totalPaxAmountEditPageValueUnder', () => payload);
        case SettingsStory.TOTAL_PAX_AMOUNT_PAGE_UPDATE_PERIOD_OVER_VALUE:
            return state.update('totalPaxAmountEditPageValueOver', () => payload);
        case SettingsStory.DAYS_FOR_VAMOOS_FLIGHTS_SYNC_VALUE:
            return state.update('daysAfterForVamoosFlightsSyncValue', () => payload);
        case SettingsStory.DAYS_TILL_VAMOOS_FLIGHTS_SYNC_VALUE:
            return state.update('daysTillForVamoosFlightsSyncValue', () => payload);
        case SettingsStory.DAYS_FOR_VAMOOS_DATES_SYNC_VALUE:
            return state.update('daysAfterForVamoosDatesSyncValue', () => payload);
        case SettingsStory.DAYS_TILL_VAMOOS_DATES_SYNC_VALUE:
            return state.update('daysTillForVamoosDatesSyncValue', () => payload);
        case SettingsStory.VAMOOS_NOTIFY_BY_MAIL_VALUE:
            return state.update('vamoosNotifyByMailValue', () => payload);
        case SettingsStory.VAMOOS_NOTIFY_BY_SMS_VALUE:
            return state.update('vamoosNotifyBySMSValue', () => payload);
        case SettingsStory.VAMOOS_NOTIFY_BY_APP_VALUE:
            return state.update('vamoosNotifyByAppValue', () => payload);
        case SettingsStory.RETURNING_CUSTOMERS_PAGE_UPDATE_INIT_STATE:
            return state.update('returningCustomersPageInitialized', () => payload);
        case SettingsStory.RETURNING_VAMOOS_PAGE_UPDATE_INIT_STATE:
            return state.update('returningVamoosPageInitialized', () => payload);
        case SettingsStory.RETURNING_CUSTOMERS_PAGE_UPDATE_VALUE:
            return state.update('returningCustomersEditPageValue', () => payload);
        default:
            return state;
    }
};
