import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class OrmManager {

    static RELOAD_OPPORTUNITY = 'RELOAD_OPPORTUNITY';

    static RELOAD_QUOTE = 'RELOAD_QUOTE';

    static RELOAD_CAMPAIGN = 'RELOAD_CAMPAIGN';

    static RELOAD_CONSENT = 'RELOAD_CONSENT';

    static RELOAD_CUSTOMER = 'RELOAD_CUSTOMER';

    static RELOAD_EMAIL_TEMPLATE = 'RELOAD_EMAIL_TEMPLATE';

    static RELOAD_EMAIL_OPTION_TEMPLATE = 'RELOAD_EMAIL_OPTION_TEMPLATE';

    static RELOAD_EMAIL_PIPELINE_TEMPLATE = 'RELOAD_EMAIL_PIPELINE_TEMPLATE';

    static RELOAD_SMS_TEMPLATE = 'RELOAD_SMS_TEMPLATE';

    static RELOAD_OVERVIEW = 'RELOAD_OVERVIEW';

    readonly onReload: EventEmitter<string> = new EventEmitter<string>();
}
