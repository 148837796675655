import { IDomainDetailData, IQuoteWorkingHoursData } from '@aclass/admin/storage/models';
import { IOfficeWorkingHoursData } from '@aclass/admin/storage/models/office.working.hours';
import { createTypedState, IModuleState } from '@aclass/core/storage/states/module.state';
import { List, Map } from 'immutable';

export interface ISettingsModuleState extends IModuleState {

    /**
     * Identifies that all data for view page is loaded
     */
    quoteWorkingHoursSearchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    quoteWorkingHoursSearchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    quoteWorkingHoursSearchPageLock: boolean | null;

    /**
     * Search form data
     */
    quoteWorkingHoursSearchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    quoteWorkingHoursSearchPageResults: List<IQuoteWorkingHoursData['id']>;

    /**
     * A list of product ids from ORM
     */
    quoteWorkingHoursSearchPageInstances: List<string>;

    /**
     * The id of record
     */
    quoteWorkingHoursEditPageRecordId: string | null;

    /**
     * The the save button lock
     */
    quoteWorkingHoursSaveLock: boolean | null;

    /**
     * Search form data
     */
    quoteWorkingHoursEditPageForm: Map<string, any>;

    /**
     * Identifies that all data for view page is loaded
     */
    officeWorkingHoursSearchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    officeWorkingHoursSearchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    officeWorkingHoursSearchPageLock: boolean | null;

    /**
     * Search form data
     */
    officeWorkingHoursSearchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    officeWorkingHoursSearchPageResults: List<IOfficeWorkingHoursData['id']>;

    /**
     * A list of product ids from ORM
     */
    officeWorkingHoursSearchPageInstances: List<string>;

    /**
     * The id of record
     */
    officeWorkingHoursEditPageRecordId: string | null;

    /**
     * The the save button lock
     */
    officeWorkingHoursSaveLock: boolean | null;

    /**
     * Search form data
     */
    officeWorkingHoursEditPageForm: Map<string, any>;

    /**
     * Identifies that all data for view page is loaded
     */
    offerValiditySearchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    offerValiditySearchPageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    offerValiditySearchPageLock: boolean | null;

    /**
     * Search form data
     */
    offerValiditySearchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    offerValiditySearchPageResults: List<IDomainDetailData['id']>;

    /**
     * A list of product ids from ORM
     */
    offerValiditySearchPageInstances: List<string>;

    /**
     * The id of record
     */
    offerValidityEditPageRecordId: string | null;

    /**
     * Offer validity
     */
    offerValidityEditPageDefaultValue: number | null;

    /**
     * Array of values data
     */
    offerValidityEditPageValues: any;

    /**
     * Array of offer validity values forms
     */
    offerValidityEditPageValuesForms: List<Map<string, any>>;

    /**
     * Array of active forms indexes
     */
    offerValidityEditPageValuesFormsActive: List<Map<string, any>>;

    /**
     * Edit page form data
     */
    offerValidityEditPageForm: Map<string, any>;

    /**
     * Identifies that all data for page is loaded
     */
    anonymizationPageInitialized: boolean | null;

    /**
     * Anonymization page form
     */
    anonymizationEditPageForm: Map<string, any>;

    /**
     * Automatic anonymization period value
     */
    anonymizationEditPageValue: number | null;

    /**
     * Identifies that all data for view page is loaded
     */
    search404PageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    search404PageCollapsed: boolean | null;

    /**
     * Trigger to identify that search is running
     */
    search404PageLock: boolean | null;

    /**
     * Search form data
     */
    search404PageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    search404PageResults: List<number>;

    /**
     * The pagination data
     */
    search404PagePagination: Map<string, number> | null;

    /**
     * The order data
     */
    search404PageOrder: Map<string, string> | null;

    /**
     * Edit page record id
     */
    edit404PageRecordId: string | null;

    /**
     * Edit page form data
     */
    edit404PageFormValue: string | null;

    /**
     * Edit page form data
     */
    edit404PageForm: Map<string, any>;

    /**
     * Identifies that all data for view page is loaded
     */
    editDateFormattingPageInitialized: boolean | null;

    /**
     * Edit page form data
     */
    editDateFormattingPageFormValue: any;

    /**
     * Edit page form data
     */
    editDateFormattingPageForm: Map<string, any>;

    /**
     * Identifies that all data for view page is loaded
     */
    editPriceFormattingPageInitialized: boolean | null;

    /**
     * Edit page form data
     */
    editPriceFormattingPageFormValue: any;

    /**
     * Edit page form data
     */
    editPriceFormattingPageForm: Map<string, any>;

    /**
     * Identifies that all data for view page is loaded
     */
    editSystemPriceFormattingPageInitialized: boolean | null;

    /**
     * Edit page data value
     */
    editSystemPriceFormattingPageFormValue: any;

    /**
     * Edit page form data
     */
    editSystemPriceFormattingPageForm: Map<string, any>;


    /**
     * Identifies that all data for page is loaded
     */
    offerSendIntervalPageInitialized: boolean | null;


    /**
     * Anonymization page form
     */
    offerSendIntervalEditPageForm: Map<string, any>;

    /**
     * Automatic anonymization period value
     */
    offerSendIntervalEditPageValue: number | null;

    /**
     * Identifies that all data for page is loaded
     */
    marginColorRangePageInitialized: boolean | null;

    /**
     * Set color for margin range
     */
    marginColorRangeEditPageForm:  Map<string, any>;

    /**
     * Identifies that all data for page is loaded
     */
    totalPaxAmountPageInitialized: boolean | null;

    /**
     * Anonymization page form
     */
    totalPaxAmountEditPageForm: Map<string, any>;

    /**
     * Total PAX amount under
     */
    totalPaxAmountEditPageValueUnder: number | null;

    /**
     * Total PAX amount over
     */
    totalPaxAmountEditPageValueOver: number | null;

    /**
     * Identifies that all data for view page is loaded
     */
    manualHandlingSearchPageInitialized: boolean | null;

    /**
     * Collapsible form status
     */
    manualHandlingSearchPageCollapsed: boolean | null;

    /**
     * Search form data
     */
    manualHandlingSearchPageForm: Map<string, any>;

    /**
     * A list of product ids from ORM
     */
    manualHandlingSearchPageInstances: List<string>;

    /**
     * Edit form data
     */
    manualHandlingEditPageForm: Map<string, any>;

    /**
     * The id of record
     */
    manualHandlingEditPageRecordId: string | null;

    /**
     * Edit form tours data
     */
    manualHandlingEditPageTours: any;

    /**
     * Edit form destinations data
     */
    manualHandlingEditPageDestinations: any;

    /**
     * Edit form other data
     */
    manualHandlingEditPageOtherValues: any;

    /**
     * Identifies that all data for page is loaded
     */
    returningCustomersPageInitialized: boolean | null;

    /**
     * Identifies that all data for page is loaded
     */
    returningVamoosPageInitialized: boolean | null;

    /**
     * Returning customers page form
     */
    returningCustomersEditPageForm: Map<string, any>;

    /**
     * Returning vamoos page form
     */
    returningVamoosEditPageForm: Map<string, any>;

    /**
     * Returning customers setting value
     */
    returningCustomersEditPageValue: boolean | null;

    /**
     * days after for Vamoos flights sync value
     */
    daysAfterForVamoosFlightsSyncValue: number | null;

    /**
     * days till for Vamoos flights sync value
     */
    daysTillForVamoosFlightsSyncValue: number | null;

    /**
     * days after for Vamoos dates sync value
     */
    daysAfterForVamoosDatesSyncValue: number | null;

    /**
     * days till for Vamoos flights sync value
     */
    daysTillForVamoosDatesSyncValue: number | null;

    /**
     * vamoos notify by mail value
     */
    vamoosNotifyByMailValue: boolean | null;

    /**
     * vamoos notify by SMS value
     */
    vamoosNotifyBySMSValue: boolean | null;

    /**
     * vamoos notify by app value
     */
    vamoosNotifyByAppValue: boolean | null;

}

export const INITIAL_SETTINGS_STATE = createTypedState<ISettingsModuleState>({
    quoteWorkingHoursSearchPageInitialized: null,
    quoteWorkingHoursSearchPageCollapsed: null,
    quoteWorkingHoursSearchPageLock: null,
    quoteWorkingHoursSearchPageForm: { },
    quoteWorkingHoursSearchPageResults: [],
    quoteWorkingHoursSearchPageInstances: [],
    quoteWorkingHoursEditPageRecordId: null,
    quoteWorkingHoursEditPageForm: { },
    quoteWorkingHoursSaveLock: null,
    officeWorkingHoursSearchPageInitialized: null,
    officeWorkingHoursSearchPageCollapsed: null,
    officeWorkingHoursSearchPageLock: null,
    officeWorkingHoursSearchPageForm: { },
    officeWorkingHoursSearchPageResults: [],
    officeWorkingHoursSearchPageInstances: [],
    officeWorkingHoursEditPageRecordId: null,
    officeWorkingHoursEditPageForm: { },
    officeWorkingHoursSaveLock: null,
    offerValiditySearchPageInitialized: null,
    offerValiditySearchPageCollapsed: null,
    offerValiditySearchPageLock: null,
    offerValiditySearchPageForm: { },
    offerValiditySearchPageResults: [],
    offerValiditySearchPageInstances: [],
    offerValidityEditPageRecordId: null,
    offerValidityEditPageDefaultValue: null,
    offerValidityEditPageForm: { },
    offerValidityEditPageValues: { },
    offerValidityEditPageValuesForms: { },
    offerValidityEditPageValuesFormsActive: { },
    anonymizationPageInitialized: null,
    anonymizationEditPageForm: { },
    anonymizationEditPageValue: null,
    search404PageInitialized: null,
    search404PageCollapsed: null,
    search404PageLock: null,
    search404PageForm: { },
    search404PageResults: [],
    search404PagePagination: null,
    search404PageOrder: null,
    edit404PageRecordId: null,
    edit404PageFormValue: null,
    edit404PageForm: null,
    editDateFormattingPageInitialized: null,
    editDateFormattingPageFormValue: null,
    editDateFormattingPageForm: null,
    editPriceFormattingPageInitialized: null,
    editPriceFormattingPageFormValue: null,
    editPriceFormattingPageForm: null,
    editSystemPriceFormattingPageInitialized: null,
    editSystemPriceFormattingPageFormValue: null,
    editSystemPriceFormattingPageForm: null,
    offerSendIntervalPageInitialized: null,
    offerSendIntervalEditPageForm: { },
    offerSendIntervalEditPageValue: null,
    marginColorRangePageInitialized: null,
    marginColorRangeEditPageForm: { },
    manualHandlingSearchPageInitialized: null,
    manualHandlingSearchPageCollapsed: null,
    manualHandlingSearchPageForm: { },
    manualHandlingSearchPageInstances: [],
    manualHandlingEditPageForm: { },
    manualHandlingEditPageRecordId: null,
    manualHandlingEditPageTours: { },
    manualHandlingEditPageDestinations: { },
    manualHandlingEditPageOtherValues: { },
    totalPaxAmountPageInitialized: null,
    totalPaxAmountEditPageForm: { },
    totalPaxAmountEditPageValueUnder: null,
    totalPaxAmountEditPageValueOver: null,
    returningCustomersPageInitialized: null,
    returningVamoosPageInitialized: null,
    returningCustomersEditPageForm: { },
    returningVamoosEditPageForm: { },
    returningCustomersEditPageValue: null,
    daysAfterForVamoosFlightsSyncValue: null,
    daysTillForVamoosFlightsSyncValue: null,
    daysAfterForVamoosDatesSyncValue: null,
    daysTillForVamoosDatesSyncValue: null,
    vamoosNotifyByMailValue: null,
    vamoosNotifyBySMSValue: null,
    vamoosNotifyByAppValue: null,
});
